import { Avatar, Button, Popover, Space } from "antd";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { accountSettings } from "../../routes";
import LogoutButton from "../auth/LogoutButton";

const UserOptions = () => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (clicked) => {
    setVisible(clicked);
  };

  return (
    <Popover
      placement="bottomRight"
      title="Opciones"
      className="mt-4"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={
        <>
          <Button onClick={() => setVisible(false)}>
            <Link to={accountSettings}>Ajustes</Link>
          </Button>
          <LogoutButton />
        </>
      }
    >
      <Space className="mt-0">
        <Avatar size={48} src="https://joeschmoe.io/api/v1/random" />
        <div className="text-left">
          <p className="font-semibold mb-1">Nombre</p>
          <span className="text-gray-400">usuario</span>
        </div>
      </Space>
    </Popover>
  );
};

export default UserOptions;
