import { Table, Tooltip } from "antd";
import { useEffect } from "react";
import { useApi } from "../../../hooks/useApi";
import
  {
    removeSocialProgram,
    socialProgramIndex
  } from "../../../services/socialProgramService";
import Tag from "../../components/misc/Tag";
import ActionColumn from "../../components/table/ActionColumn";
import ProgramModal from "../edit/ProgramModal";
import TableHeader from "./TableHeader";

const statusTags = {
  "En Progreso": {
    background: "#fbfcbc",
    border: "#ffd669",
    color: "#333333",
  },
  "No Iniciado": {
    background: "#ff9598",
    border: "#ff0027",
    color: "#333333",
  },
  Finalizado: {
    background: "#bfffcb",
    border: "#00cc00",
    color: "#333333",
  },
};

const ProgramsTable = () => {
  const [response, setResponse] = useApi(socialProgramIndex);

  useEffect(() => {
    setResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1 className="index-table-title text-3xl">Programas</h1>
      <Table
        showSorterTooltip={false}
        dataSource={response.data ?? []}
        title={(currentPageData) => <TableHeader data={currentPageData} />}
        loading={response.isFetching}
        pagination={false}
        className="mt-12 px-16 pb-10"
        rowClassName="group"
        columns={[
          {
            title: "NOMBRE PROGRAMA",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => `${record["precomposition_name"].name} ${record.name}`
          },
          {
            title: "PROGRESO",
            dataIndex: "progress",
            key: "progress",
            sorter: () => true,
            render: (text, record) => (
              <Tooltip
                title={
                  record["start_date"]
                    ? "Listo para iniciar"
                    : "Falta asignar usuarios a grupos de trabajo"
                }
                placement="bottomLeft"
              >
                <div className="rounded-full bg-gray-300 w-4/5 h-2">
                  <div
                    className="bg-primary h-full rounded-full"
                    style={{ width: record["start_date"] ? "100%" : "80%" }}
                  />
                </div>
              </Tooltip>
            ),
          },
          {
            title: "DEPENDENCIA",
            dataIndex: "dependency",
            key: "dependency",
            sorter: () => true,
            render: (text, record) => <Tag border={record.entity.color} text={record.entity.name} />,
          },
          {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            sorter: () => true,
            render: () => (
              <Tag {...statusTags["Finalizado"]} text="Finalizado" />
            ),
          },
          {
            title: "ACCIÓN",
            key: "actions",
            align: "right",
            render: (text, record) => (
              <ActionColumn
                record={record}
                modal={<ProgramModal id={record.id} onSuccess={setResponse} />}
                showModal={<ProgramModal id={record.id} editable={false} />}
                deleteRecord={removeSocialProgram}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default ProgramsTable;
