import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/social_programs";

const workgroupFormatter = (group) =>
  Object.keys(group).map((g) => ({
    workgroup_members: [
      ...group[g].coordinador.map((i) => ({
        staff_id: i.id,
        staff_type_id: 1,
      })),
      ...group[g].jefe.map((i) => ({
        staff_id: i.id,
        staff_type_id: 2,
      })),
      ...group[g].brigadista.map((i) => ({
        staff_id: i.id,
        staff_type_id: 3,
      })),
    ],
    name: `Grupo #${g}`,
  }));

export const createSocialProgram = (model) =>
  apiProvider.post("/api/v1/onboarding/social_programs", {
    social_program_onboarding: {
      social_program: {
        entity_id: model.entity,
        start_date: model.dates[0]._d,
        finish_date: model.dates[1]._d,
        name: model.name,
        properties: JSON.stringify(model.properties),
        precomposition_name_id: model.precomposition,
        municipality_id: model.municipality,
        location_id: model.location,
      },
      workgroups: workgroupFormatter(JSON.parse(model.groups)),
    },
  });

export const editSocialProgram = (model) =>
  apiProvider.patch("/api/v1/onboarding/social_programs", {
    ...(model.name && { name: model.name }),
    ...(model.entity && { entity_ids: [model.entity] }),
    ...(model.dates && { start_date: [model.dates[0]] }),
    ...(model.dates && { finish_date: [model.dates[1]] }),
    ...(model.precomposition && {
      precomposition_name_id: model.precomposition,
    }),
    ...(model.municipality && { municipality_id: model.municipality }),
    ...(model.location && { location_id: model.location }),
    properties: JSON.stringify(model.properties),
    id: model.id,
  });

export const socialProgramIndex = () => apiProvider.getAll(baseURL);

export const socialProgramShow = (id) => apiProvider.getSingle(baseURL, id);

export const removeSocialProgram = (id) => apiProvider.remove(baseURL, id);
