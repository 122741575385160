import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/vehicles";

export const createVehicle = (model) =>
  apiProvider.post(baseURL, {
    vehicle: {
      year: model.year,
      license_plate: model.plate,
      color: model.color,
      vehicle_model_id: model.model,
      entity_ids: [model.entity],
    },
  });

export const editVehicle = (model) =>
  apiProvider.put(baseURL, {
    id: model.id,
    vehicle: {
      year: model.year,
      license_plate: model.plate,
      color: model.color,
      vehicle_model_id: model.model,
      entity_ids: [model.entity],
    },
  });

export const vehiclesIndex = () => apiProvider.getAll(baseURL);

export const removeVehicle = (id) => apiProvider.remove(baseURL, id);

// Brands & Models
export const brandsIndex = () => apiProvider.getAll("/api/v1/vehicle_brands");

export const modelsIndex = (id) =>
  apiProvider.getAll(`/api/v1/vehicle_brands/${id}`);
