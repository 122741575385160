import { Form, Select } from "antd";
import { useEffect } from "react";
import { useApi } from "../../../hooks/useApi";
import { dependenciesIndex } from "../../../services/dependencyService";
import { modalInput } from "../../styles/formFields";

const DependencySelect = ({ className, initialValue, placeholder, label }) => {
  const [entities, setEntities] = useApi(dependenciesIndex);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setEntities(), []);

  const handleSearch = (input, option) => {
    const term = new RegExp(`${input}.*$`, "i");

    return option.search(term) !== -1;
  };

  return (
    <div className={className}>
      <Form.Item
        label={label ?? "Dependencia"}
        name="entity"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: "Por favor elija una dependencia" }]}
        initialValue={initialValue}
      >
        <Select
          placeholder={placeholder ?? "Selecciona una dependencia"}
          style={modalInput}
          bordered={false}
          showSearch
          filterOption={(input, option) => handleSearch(input, option.children)}
        >
          {entities.isSuccess &&
            entities.data.map((entity) => (
              <>
                <Select.Option value={entity.id}>{entity.name}</Select.Option>

                {entity["sub_entities"].map((subentity) => (
                  <Select.Option value={subentity.id}>{subentity.name}</Select.Option>
                ))}
              </>
            ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DependencySelect;
