import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import DependencyCascader from "../../components/form/DependencyCascader";
import LoadCsv from "../../components/form/LoadCsv";
import CreateGoodService from "../create/CreateGoodService";

const TableHeader = ({ data, onSearch, onReset }) => {
  const [response, setResponse] = useState({})
  
  const handleSearch = (e) => {
    if (e.target.value === "") {
      onReset();
      return;
    }

    const term = new RegExp(`${e.target.value}.*$`, "i");
    const result = data.filter((e) => e.name.search(term) !== -1);

    onSearch(result);
  };

  return (
    <div className="bg-slate-100 px-7 py-6">
      <div className="w-6/12 mb-7 flex">
        <Input
          suffix={<SearchOutlined />}
          className="mr-5"
          placeholder="Buscar"
          type="search"
          onChange={(e) => handleSearch(e)}
        />
        <div className="text-left w-full">
          <DependencyCascader />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between w-1/4">
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total bienes</span>
            <p className="text-lg font-semibold">
              {data.filter((i) => i.type === "Bien").length} bienes
            </p>
          </div>
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total servicios</span>
            <p className="text-lg font-semibold">
              {data.filter((i) => i.type === "Servicio").length} servicios
            </p>
          </div>
        </div>
        <div>
          <LoadCsv listing="Inventario" response={response} sendData={() => setResponse({error: "No hay ruta"})} />
          <CreateGoodService />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
