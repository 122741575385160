import { Form, Button, Space, Modal } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import ModalTitle from "../../components/modal/ModalTitle";
import UserForm from "../form/UserForm";
import { useState } from "react";

const CreateUser = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" size="large" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <PlusCircleOutlined /> NUEVA
        </Space>
      </Button>
      <Modal
        title={<ModalTitle title="Crear nuevo" subtitle="Usuario" />}
        closeIcon={<CloseCircleOutlined />}
        visible={visible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <UserForm submit={handleCloseModal} pointer={form} />
      </Modal>
    </>
  );
};

export default CreateUser;
