import { CloseCircleOutlined, DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { addFlash } from "../flash/flashSlice";
import ModalButtons from "../modal/ModalButtons";
import ModalTitle from "../modal/ModalTitle";

const DeleteButton = ({ id, removeRecord, modalOptions }) => {
  const [response, setResponse] = useApi(() => removeRecord(id));
  const [modalDisplay, setModalDisplay] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (response.isSuccess) {
      modalOptions.afterDelete();
      setModalDisplay(false);
      dispatch(
        addFlash({
          status: "success",
          message: "Se ha eliminado satisfactoriamente ✅",
        })
      );
    } 
    if (response.error) {
      setModalDisplay(false);
      dispatch(
        addFlash({
          status: "error",
          message: "¡Hubo problemas! no se pudo eliminar 🚨",
        })
      );
    }
  }, [dispatch, modalOptions, response]);

  const handleRemove = () => {
    if (modalOptions) {
      setModalDisplay(true);
    } else {
      setResponse();
    }
  };

  return (
    <>
      <Tooltip placement="right" title="Eliminar">
        <Button
          onClick={() => handleRemove()}
          type="text"
          danger
          size="small"
        >
          <DeleteFilled />
        </Button>
      </Tooltip>

      {modalOptions && (
        <Modal
          title={
            <ModalTitle
              title={modalOptions.title}
              subtitle={modalOptions.subtitle}
            />
          }
          closeIcon={<CloseCircleOutlined />}
          footer={null}
          visible={modalDisplay}
          onCancel={() => setModalDisplay(false)}
        >
          <p className="text-black font-medium text-lg">
            {modalOptions.message}
          </p>
          <ModalButtons
            onCancel={() => setModalDisplay(false)}
            onOk={() => setResponse()}
          />
        </Modal>
      )}
    </>
  );
};

DeleteButton.propTypes = {
  id: PropTypes.number.isRequired,
  removeRecord: PropTypes.func.isRequired,
};

export default DeleteButton;
