import { Table } from "antd";
import { useEffect } from "react";
import { useApi } from "../../../hooks/useApi";
import {
  personnelIndex,
  removePersonnel,
} from "../../../services/personnelService";
import Tag from "../../components/misc/Tag";
import ActionColumn from "../../components/table/ActionColumn";
import EditPersonnel from "../edit/EditPersonnel";
import TableHeader from "./TableHeader";

const PersonnelTable = () => {
  const [response, setResponse] = useApi(personnelIndex);

  useEffect(() => {
    setResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Personal</h1>
      </div>
      <Table
        title={() => <TableHeader />}
        showSorterTooltip={false}
        dataSource={response.data ?? []}
        loading={response.isFetching}
        pagination={false}
        className="mt-8 px-16"
        columns={[
          {
            title: "Nombre",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: "Cargo",
            dataIndex: "rol",
            filters: [
              {
                text: "Jefe de brigada",
                value: "Jefe de brigada",
              },
              {
                text: "Coordinador",
                value: "Coordinador",
              },
              {
                text: "Brigadista",
                value: "Brigadista",
              },
            ],
            sorter: (a, b) => a.rol.name.localeCompare(b.rol.name),
            onFilter: (value, record) => record.rol === value,
            render: (text, record) => record.rol.name,
          },
          {
            title: "Dependencia",
            dataIndex: "dependency",
            sorter: (a, b) => a.entity.name.localeCompare(b.entity.name),
            render: (text, record) => <Tag border="#4567ad" text={record.entity.name} />,
            filters: response.isSuccess
              ? [...new Set(response.data.map((i) => i.entity.name))]
                  .sort((a, b) => a.localeCompare(b))
                  .map((i) => ({ text: i, value: i }))
              : [],
            onFilter: (value, record) => record.entity.name === value,
          },
          {
            title: "Teléfono",
            dataIndex: "cellphone",
            sorter: (a, b) => a.cellphone.localeCompare(b.cellphone),
          },
          {
            title: "Opciones",
            key: "actions",
            render: (text, record) => (
              <ActionColumn
                record={record}
                modal={
                  <EditPersonnel record={record} onSuccess={setResponse} />
                }
                deleteRecord={removePersonnel}
                deleteOptions={{
                  title: "Alerta",
                  subtitle: "Eliminar Brigadista",
                  message:
                    "Estimado usuario esta a punto de eliminar un brigadista de la lista general.",
                  afterDelete: setResponse,
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default PersonnelTable;
