// Custom colors
export const colors = {
  primary: 'rgb(89, 50, 234)',
  modalInputBg: '#f9fbff',
  danger: '#fe6969',
  grey: {
    1: '#c4c4c4',
    2: '#888888',
    3: '#e1e2ea',
    4: '#616269',
    5: "#e7e7e7",
    6: '#808080',
    8: "#F9FBFF"
  },

}

// Modals
export const modalInput = {
  backgroundColor: colors.modalInputBg,
  borderRadius: "8px",
  borderWidth: "1px",
};

export const secondaryInput = {
  backgroundColor: colors.grey[8],
  borderRadius: "10px",
  borderWidth: "0px",
  paddingLeft: "2rem",
};
