import { PlusOutlined } from "@ant-design/icons";
import { Badge, Form, Input, Upload } from "antd";
import { colors, modalInput } from "../../styles/formFields";

const EvidenceFields = () => {
  return (
    <>
      <Badge color={colors.primary} text="Evidencias" className="font-bold" />

      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        className="my-4"
        locale={{
          uploading: "Cargando",
          removeFile: "Borrar imagen",
          downloadFile: "Descargar",
          uploadError: "Ocurrió un error",
          previewFile: "Ver imagen",
        }}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Cargar</div>
        </div>
      </Upload>

      <Form.Item
        name="description"
        label={
          <label>
            Descripción{" "}
            <span className="text-[10px] text-lightgrey-200">opcional</span>
          </label>
        }
      >
        <Input.TextArea
          placeholder="Escriba aqui su descripción"
          style={{ ...modalInput, height: "155px" }}
        />
      </Form.Item>
    </>
  );
};

export default EvidenceFields;
