import { Table } from "antd";
import { showGroup } from "../../../routes";
import ActionColumn from "../../components/table/ActionColumn";
import TableHeader from "./TableHeader";

const columns = [
  { title: 'Nombre', dataIndex: 'name' },
  { title: 'Dependencia', dataIndex: 'dependency' },
  { title: 'Miembros', dataIndex: 'members' },
  {
    title: "Opciones",
    key: "actions",
    render: (text, record) => (
      <ActionColumn
        record={record}
        showRecord={showGroup}
        deleteRecord={() => console.log('Eliminando...')}
      />
    ),
  },
];

const data = [
  {id: 1, name: 'Grupo 1', dependency: 'Salud', 'members': '11'},
  {id: 2, name: 'Grupo 2', dependency: 'Deporte', 'members': '22'},
  {id: 3, name: 'Grupo 3', dependency: 'Agricultura', 'members': '33'},
  {id: 4, name: 'Grupo 4', dependency: 'Educación', 'members': '44'},
  {id: 5, name: 'Grupo 5', dependency: 'Ambiental', 'members': '55'},
];

const GroupsTable = () => {
  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Grupos de trabajo</h1>
      </div>
      <Table
        title={() => <TableHeader />}
        dataSource={data}
        columns={columns}
        loading={false}
        pagination={false}
        className="mt-12 px-16"
      />
    </>
  );
};

export default GroupsTable;
