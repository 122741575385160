import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/mexico";

export const statesIndex = () =>
  apiProvider.getAll(`${baseURL}/states`, );

export const municipalitiesIndex = (id) =>
  apiProvider.getAll(`${baseURL}/states/${id}`);

export const locationsIndex = (id) =>
  apiProvider.getAll(`${baseURL}/municipalities/${id}`);
