import { UserOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import LoadCsv from "../../components/form/LoadCsv";
import CreatePersonnel from "../create/CreatePersonnel";

const TableHeader = ({ setType, users }) => {
  return (
    <div className="bg-slate-100 px-7 py-6">
      <div className="w-1/3 mb-7 flex">
        <Input
          suffix={<UserOutlined />}
          className="mr-5"
          placeholder="Buscar"
          type="search"
        />
        <div className="text-left w-1/3">
          <Select placeholder="Programa" className="w-full" />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between w-1/4">
          <div className="h-12 text-left">
            <span className="text-zinc-500">
              Total de Brigadistas Registrados
            </span>
            <p className="text-lg font-semibold">13 usuarios</p>
          </div>
        </div>
        <div>
          <LoadCsv listing="Personal" resource="staffs" name="staff_file" />
          <CreatePersonnel />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
