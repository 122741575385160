import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Radio, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "../../../../hooks/useApi";
import { personnelIndexFilter } from "../../../../services/personnelService";
import ModalButtons from "../../../components/modal/ModalButtons";
import ModalTitle from "../../../components/modal/ModalTitle";
import { assignMember } from "./groupSlice";

const memberTypes = {
  coordinador: "Coordinador",
  jefe: "Jefe de brigada",
  brigadista: "Brigadista",
};

const memberTypesIds = {
  coordinador: 1,
  jefe: 2,
  brigadista: 3,
};

const AssignUserModal = ({ visible, onCancel, userInfo, groupId }) => {
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [response, setResponse] = useApi(() =>
    personnelIndexFilter(memberTypesIds[userInfo.userType])
  );

  useEffect(() => {
    setResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (response.isSuccess) {
      setMembers(response.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setMembers(response.data);
      return;
    }

    const term = new RegExp(`${e.target.value}.*$`, "i");
    const result = members.filter((e) => e.name.search(term) !== -1);

    setMembers(result);
  };

  const handleMemberAssign = (value) => {
    dispatch(
      assignMember({
        groupId,
        userType: userInfo.userType,
        userId: value,
        index: userInfo.userId,
      })
    );

    onCancel();
  };

  return (
    <Modal
      visible={visible}
      closeIcon={<CloseCircleOutlined />}
      onCancel={onCancel}
      footer={null}
      title={
        <ModalTitle
          title="Seleccionar  Personal"
          subtitle={memberTypes[userInfo.userType]}
        />
      }
      width={689}
      destroyOnClose
      className="z-50"
    >
      <Input
        placeholder="Buscar"
        suffix={<SearchOutlined />}
        onChange={(e) => handleSearch(e)}
        style={{
          borderRadius: 6,
          backgroundColor: "#fff",
          boxShadow: "0px 10px 15px rgba(226, 236, 249, 0.5)",
        }}
      />

      <div className="border border-lightgrey-500 rounded-lg px-6 py-7 mt-6 h-72 overflow-y-auto bg-lightgrey-700">
        <Radio.Group onChange={(e) => setSelectedUser(e.target.value)}>
          <Space direction="vertical">
            {members.map((i) => (
              <Radio value={i.id} style={{ fontSize: 16 }} key={i.name}>
                {i.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <ModalButtons
        nextText="Agregar"
        align="right"
        onCancel={onCancel}
        onOk={() => handleMemberAssign(selectedUser)}
      />
    </Modal>
  );
};

export default AssignUserModal;
