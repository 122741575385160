import { CloseCircleOutlined, EditFilled } from "@ant-design/icons";
import { Button, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { editVehicle } from "../../../services/vehicleService";
import { addFlash } from "../../components/flash/flashSlice";
import ModalButtons from "../../components/modal/ModalButtons";
import ModalTitle from "../../components/modal/ModalTitle";
import VehicleForm from "../form/VehicleForm";

const EditVehicle = ({ record, onSuccess }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [request, setRequest] = useApi(() =>
    editVehicle({ ...form.getFieldsValue(), id: record.id })
  );

  const handleCloseModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (request.isSuccess && !request.error) {
      setVisible(false);
      onSuccess();
      dispatch(
        addFlash({
          message: "Se ha actualizado el vehiculo satisfactoriamente ✅",
          status: "success",
        })
      );
    }

    if (request.error) {
      console.log(request.error);
      dispatch(
        addFlash({
          message: `¡Hubo problemas! el vehículo no se pudo actualizar 🚨`,
          status: "error",
        })
      );
      setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.isSuccess, request.error]);

  return (
    <>
      <Button type="text" size="small" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <EditFilled style={{ color: "rgb(0, 208, 208)" }} />
        </Space>
      </Button>
      <Modal
        title={<ModalTitle title="Editar" subtitle="Vehículo" />}
        closeIcon={<CloseCircleOutlined />}
        visible={visible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <VehicleForm submit={handleCloseModal} pointer={form} values={record} />
        <ModalButtons
          onCancel={handleCloseModal}
          onOk={() => setRequest()}
          nextText="Actualizar"
          className="w-40 h-9 text-md"
          align="right"
        />
      </Modal>
    </>
  );
};

export default EditVehicle;
