import { createSlice, current } from "@reduxjs/toolkit";

const initialState = () => ({
  1: {
    id: 1,
    coordinador: [{}],
    jefe: [{}],
    brigadista: [{}],
  },
});

const findNextId = (current, state) => {
  if (!state[current + 1]) {
    return current + 1;
  } else {
    return findNextId(current - 1, state);
  }
};

export const groupSlice = createSlice({
  name: "groups",
  initialState,

  reducers: {
    addGroup: (state) => {
      const nextId = findNextId(Object.keys(state).length, state);

      return {
        ...state,
        [nextId]: {
          id: nextId,
          coordinador: [{}],
          jefe: [{}],
          brigadista: [{}],
        },
      };
    },
    removeGroup: (state, action) => {
      const id = action.payload;
      const nextState = { ...state };

      delete nextState[id];

      return nextState;
    },
    addMember: (state, action) => {
      const { groupId, userType } = action.payload;

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          [userType]: [...state[groupId][userType], {}],
        },
      };
    },
    assignMember: (state, action) => {
      const { groupId, userType, index, userId } = action.payload;
      const currentGroup = [...current(state)[groupId][userType]];

      currentGroup.splice(index, 1, { id: userId });

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          [userType]: currentGroup,
        },
      };
    },
    removeMember: (state, action) => {
      const { groupId, userType, position } = action.payload;

      const removed = [...state[groupId][userType]];
      removed.splice(position, 1);

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          [userType]: [...removed],
        },
      };
    },
    resetGroups: (state) => (
      {
        1: {
          id: 1,
          coordinador: [{}],
          jefe: [{}],
          brigadista: [{}],
        },
      }
    ),
  },
});

export const { addGroup, removeGroup, addMember, assignMember, removeMember, resetGroups } =
  groupSlice.actions;

export const selectGroups = (state) => state.groups;

export default groupSlice.reducer;
