import { Table } from "antd";
import ActionColumn from "../../components/table/ActionColumn";
import EditLocation from "../edit/EditLocation";
import TableHeader from "./TableHeader";

const columns = [
  { title: "NOMBRE", dataIndex: "name" },
  { title: "TIPO", dataIndex: "type" },
  {
    title: "ACCIÓN",
    key: "actions",
    render: (text, record) => (
      <ActionColumn
        record={record}
        deleteRecord={() => console.log("Borrando ubicacion")}
        modal={<EditLocation record={record} />}
      />
    ),
  },
];

const data = [
  { name: "Nombre de ubicacion 1", type: "Estado" },
  { name: "Nombre de ubicacion 2", type: "Municipio" },
  { name: "Nombre de ubicacion 3", type: "Junta auxiliar" },
  { name: "Nombre de ubicacion 4", type: "Ciudad" },
  { name: "Nombre de ubicacion 5", type: "Urbanismo" },
];

const LocationsTable = () => {
  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Ubicaciones</h1>
      </div>
      <Table
        dataSource={data}
        title={() => <TableHeader data={data} />}
        columns={columns}
        loading={false}
        pagination={false}
        className="mt-12 px-16"
      />
    </>
  );
};

export default LocationsTable;
