import { Space } from "antd";
import { PropTypes } from "prop-types";

const ModalButtons = ({ onCancel, onOk, nextText, align, className = "" }) => (
  <div className="flex mt-10" style={{justifyContent: align ?? "center"}}>
    <Space size="large">
      <button
        type="button"
        onClick={onCancel}
        className={`rounded-lg py-1 w-44 h-11 text-lg text-lightgrey-400 ${className}`}
      >
        Cancelar
      </button>
      <button
        type="button"
        onClick={onOk}
        className={`rounded-lg py-1 w-44 h-11 text-lg bg-primary text-white ${className}`}
      >
        {nextText ?? "Aceptar"}
      </button>
    </Space>
  </div>
);

ModalButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  nextText: PropTypes.string,
  align: PropTypes.string,
};

export default ModalButtons;
