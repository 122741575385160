import { Modal, Space, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ModalTitle from "../../../components/modal/ModalTitle";
import AddUser from "./AddUser";
import styles from "./GroupCard.module.css";
import { removeGroup } from "./groupSlice";
import UserSelect from "./UserSelect";
import ModalButtons from "../../../components/modal/ModalButtons";
import AssignUserModal from "./AssignUserModal";

const GroupCard = ({ id, coordinador, jefe, brigadista, assignment }) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const handleRemoveGroup = () => {
    dispatch(removeGroup(id));
    setModalVisible(false);
  };

  const handleAssignUser = (userId, userType) => {
    if (!assignment) return;

    setAssignModal(true);
    console.log(userId)
    setUserInfo({ userId, userType, groupId: id });
  };

  const userCounts = () => (
    <Space direction="vertical">
      <span>{coordinador.length} {`coordinador${coordinador.length > 1 ? 'es' : ''}`}</span>
      <span>{jefe.length} {`jefe${jefe.length > 1 ? 's' : ''}`} de brigada</span>
      <span>{brigadista.length} {`brigadista${brigadista.length > 1 ? 's' : ''}`}</span>
    </Space>
  );

  return (
    <>
      <div className={styles.groupCard}>
        <span className={styles.groupName}>Grupo #{id}</span>
        <button
          type="button"
          className={styles.groupDelete}
          onClick={() => setModalVisible(true)}
        >
          Eliminar
        </button>
        <Space wrap align="end" size={[10, 37]}>
          <AddUser groupId={id} userType="coordinador" />
          {coordinador.map((user, position) => (
            <UserSelect
              info={user}
              position={position}
              groupId={id}
              userType="coordinador"
              openModal={handleAssignUser}
            />
          ))}
          <AddUser groupId={id} userType="jefe" />
          {jefe.map((user, position) => (
            <UserSelect
              info={user}
              position={position}
              groupId={id}
              userType="jefe"
              openModal={handleAssignUser}
            />
          ))}
          <AddUser groupId={id} userType="brigadista" />
          {brigadista.map((user, position) => (
            <UserSelect
              info={user}
              position={position}
              groupId={id}
              userType="brigadista"
              openModal={handleAssignUser}
            />
          ))}
        </Space>
        <Tooltip title={userCounts}>
          <span className="text-gray-400 ml-2 min-w-max">
            {coordinador.length + jefe.length + brigadista.length} Miembros
          </span>
        </Tooltip>
      </div>
      <Modal
        visible={modalVisible}
        closeIcon={<CloseCircleOutlined />}
        onCancel={() => setModalVisible(false)}
        footer={null}
        title={<ModalTitle title="Aviso" subtitle="Eliminación de Grupo" />}
        width={460}
        destroyOnClose
      >
        <p className="text-black text-lg px-3">
          Estimado usuario, está a punto de eliminar un grupo de la lista.
        </p>
        <ModalButtons
          onCancel={() => setModalVisible(false)}
          onOk={() => handleRemoveGroup()}
        />
      </Modal>
      <AssignUserModal
        visible={assignModal}
        onCancel={() => setAssignModal(false)}
        userInfo={userInfo}
        groupId={id}
      />
    </>
  );
};

export default GroupCard;
