import { EditFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteButton from "./DeleteButton";

const ActionsColumn = ({
  record,
  showRecord,
  showModal,
  deleteRecord,
  modal,
  deleteOptions,
}) => (
  <>
    <Tooltip placement="left" title="Editar">
      <span>
        {modal ?? (
          <Button type="text" size="small">
            <Link to={showRecord(record.id)}>
              <EditFilled style={{ color: "rgb(0, 208, 208)" }} />
            </Link>
          </Button>
        )}
      </span>
    </Tooltip>
    {showModal && (
      <Tooltip placement="top" title="Ver">
        <span>{showModal}</span>
      </Tooltip>
    )}
    <DeleteButton
      id={record.id}
      removeRecord={deleteRecord}
      modalOptions={deleteOptions}
    />
  </>
);

ActionsColumn.propTypes = {
  record: PropTypes.object.isRequired,
  showRecord: PropTypes.func,
  deleteRecord: PropTypes.func.isRequired,
  deleteOptions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    message: PropTypes.string,
    afterDelete: PropTypes.func
  }),
};

export default ActionsColumn;
