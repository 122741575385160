import { Button, Popover } from "antd";
import { useDispatch } from "react-redux";
import { UserFilled, CheckFilled } from "../../../icons";
import styles from "./GroupCreation.module.css";
import { removeMember } from "./groupSlice";

const UserSelect = ({ groupId, userType, position, openModal, info }) => {
  const dispatch = useDispatch();

  const handleRemoveUser = () => {
    dispatch(removeMember({ groupId, userType, position }));
  };

  const handleAssign = () => {
    openModal(position, userType);
  };

  const content = (
    <>
      <Button
        type="primary"
        block
        className="w-1/2 mb-3"
        disabled={info.id}
        onClick={handleAssign}
      >
        Asignar Usuario
      </Button>
      <Button type="ghost" block onClick={handleRemoveUser}>
        Eliminar
      </Button>
    </>
  );

  return (
    <Popover trigger="click" title="Opciones" content={content}>
      <div
        className={`flex flex-col w-min justify-around py-2 items-center rounded relative ${
          styles[`${userType}-dim`]
        }`}
      >
        {info.id && <CheckFilled className="absolute top-2 left-2" />}
        <UserFilled className="rounded-full bg-gray-100 h-min w-min p-2 line" />
        <span className="mx-2 text-center text-xs text-lightgrey-400">
          {info.id ? "Usuario lleno" : "Usuario libre"}
        </span>
      </div>
    </Popover>
  );
};

export default UserSelect;
