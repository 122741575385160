import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/staffs";

export const createPersonnel = (model) =>
  apiProvider.post(baseURL, {
    staff: {
      ...model,
      rol_id: 1,
      entity_id: model.entity,
    },
  });

export const editPersonnel = (model) =>
  apiProvider.put(baseURL, {
    ...model,
    id: model.id,
    entity_id: model.entity,
  });

export const personnelIndex = () => apiProvider.getAll(baseURL);

export const personnelIndexFilter = (type) =>
  apiProvider.getAll(`${baseURL}?staff_type=${type}`);

export const removePersonnel = (id) => apiProvider.remove(baseURL, id);
