import axios from 'axios';
const baseURL = '/auth/users'

export const register = async (email, password) => {
  try {
    const response = await axios.post(`${baseURL}/register`, {
      email,
      password,
    });

    return { loggedIn: true, data: response.data };
  }

  catch (error) {
    if (error.response) {
      // The resquest was made and the server responded with error
      const { data: { errors } } = error.response;
      return { loogedIn: false, errors }
    } else if (error.request) {
      // The request was made but no response was received
      return { loogedIn: false, errors: ['No hemos podido verificar tus credenciales'] }
    } else {
      // Something bad happened
      return { loogedIn: false, errors: ['Ha ocurrido un error, intenta de nuevo'] }
    }
  }
};

export const signin = async (email, password) => {
  try {
    const response = await axios.post(`${baseURL}/login`, {
      email,
      password,
    });

    return { loggedIn: true, data: response.data };
  }

  catch (error) {
    if (error.response) {
      // The resquest was made and the server responded with error
      const { data: { errors } } = error.response;
      return { loogedIn: false, errors }
    } else if (error.request) {
      // The request was made but no response was received
      return { loogedIn: false, errors: ['No hemos podido verificar tus credenciales'] }
    } else {
      // Something bad happened
      return { loogedIn: false, errors: ['Ha ocurrido un error, intenta de nuevo'] }
    }
  }
};

export const signout = async (token) => {
  try {
    const response = await axios.delete(`${baseURL}/sign_out`, {
      headers: {
        Authorization: token
      }
    });

    return { loggedOut: true, data: response.data };
  }

  catch (error) {
    if (error.response) {
      // The resquest was made and the server responded with error
      const { data: { errors } } = error.response;
      return { loogedIn: false, errors }
    } else if (error.request) {
      // The request was made but no response was received
      return { loogedIn: false }
    } else {
      // Something bad happened
      return { loogedIn: false }
    }
  }
};

export const validateToken = async (token) => {
  try {
    const response = await axios.get(`${baseURL}/validate_token`, {
      headers: {
        Authorization: token
      }
    });

    return { loggedIn: true, data: response.data }
  }

  catch (error) {
    return { loggedIn: false }
  };
};
