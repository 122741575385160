import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

const TableHeader = ({ data }) => {
  return (
    <div className="bg-slate-100 px-7 py-6">
      <div className="w-1/4 mb-7 flex">
        <Input
          suffix={<SearchOutlined />}
          className="mr-5"
          placeholder="Buscar"
          type="search"
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between w-1/4">
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total ubicaciones</span>
            <p className="text-lg font-semibold">{data.length} ubicaciones</p>
          </div>
        </div>
        <div>
          <Button size="large" className="mr-5">
            <Space className="text-sm">
              <UploadOutlined /> CARGAR .CSV
            </Space>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
