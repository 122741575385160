import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/services";

export const createService = (model) =>
  apiProvider.post(baseURL, {
    service: {
      name: model.name,
      social_program_id: model.social_program_id,
      type_of_service_ids: model.type_ids,
    },
  });

export const editService = (model) =>
  apiProvider.patch(baseURL, {
    id: model.id,
    name: model.name,
    social_program_id: model.social_program_id,
  });

export const servicesIndex = () => apiProvider.getAll(baseURL);

export const serviceShow = (id) => apiProvider.getSingle(baseURL, id);

export const removeService = (id) => apiProvider.remove(baseURL, id);
