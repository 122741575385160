import { Drawer, Timeline } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { programInventoryShow } from "../../../services/programInventoryService";
import { selectSession } from "../../auth/sessionSlice";

const HistoryDrawer = ({ visible, setVisible}) => {
  const { token } = useSelector(selectSession);
  const [response, setResponse] = useApi(() => programInventoryShow(1, token));
  
  useEffect(() => {
    if (visible) {
      setResponse();
    }
  }, [setResponse, visible]);

  return (
    <Drawer
      title="Historial de operaciones"
      placement="right"
      onClose={() => setVisible(false)}
      visible={visible}
    >
      <Timeline mode="right">
      {response.isSuccess && (
        response.data.sp_inventory_operations.map(op => (
        <Timeline.Item label="21-04-22">
         {op.operation_type}
         {' '}
         {op.available}
        </Timeline.Item>
        ))
      )}
        <Timeline.Item label="06-03-22">
          Salieron 20 articulos
        </Timeline.Item>
        <Timeline.Item label="07-03-22">
          Se agregaron 7 articulos
        </Timeline.Item>
        <Timeline.Item label="08-03-22">
          Se devolvió el sobrante
        </Timeline.Item>
      </Timeline>
    </Drawer>
  )
};

export default HistoryDrawer;