/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import
  {
    locationsIndex,
    municipalitiesIndex,
    statesIndex
  } from "../../../services/locationService";

const SearchSelect = ({ name, change, data = [] }) => {
  const handleSearch = (input, option) => {
    const term = new RegExp(`${input}.*$`, "i");

    return option.search(term) !== -1;
  };

  return (
    <Form.Item name={name}>
      <Select
        showSearch
        placeholder="Buscar"
        placement="bottomLeft"
        className="w-full"
        bordered={false}
        filterOption={(input, option) => handleSearch(input, option.children)}
        onChange={(value) => change(value)}
        style={{
          backgroundColor: "#fff",
          borderBottom: "1px solid #E4E6EF",
          borderRadius: "8px",
          padding: "2px 0",
        }}
      >
        {data?.map((option) => (
          <Select.Option value={option.id}>{option.name}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const ArrowNext = ({ active }) => (
  <span
    className={`absolute top-1/2 -right-6 h-10 w-10 rounded-full after:border-r-4 after:border-t-4 after:border-white after:absolute after:top-[13px] after:left-[9px] after:w-[18px] after:h-[18px] z-10 ${active ? "bg-primary" : "bg-lightgrey-300"}`}
    style={{ transform: "rotate(45deg)" }}
  />
);

const LocationSearch = () => {
  const [states, setStates] = useApi(statesIndex);

  const [stateId, setStateId] = useState();
  const [municipalities, setMunicipalities] = useApi(() =>
    municipalitiesIndex(stateId)
  );

  const [municipalityId, setMunicipalityId] = useState();
  const [locations, setLocations] = useApi(() =>
    locationsIndex(municipalityId)
  );

  useEffect(() => {
    if (!states.data && !states.isSuccess) {
      setStates();
    }
  }, []);

  useEffect(() => {
    if (stateId > 0) {
      setMunicipalities();
    }
  }, [stateId]);

  useEffect(() => {
    if (municipalityId > 0) {
      setLocations();
    }
  }, [municipalityId]);

  return (
    <div className="w-full my-8">
      <div className="h-72 inline-block w-1/5 pr-3">
        <div className="bg-lightgrey-700 border border-lightgrey-500 relative rounded-lg h-full">
          <SearchSelect change={setStateId} data={states.data} />
          <ArrowNext active={stateId > 0} />
        </div>
      </div>
      <div className="h-72 inline-block w-1/5 pr-3">
        <div className="bg-lightgrey-700 border border-lightgrey-500 relative rounded-lg h-full">
          <SearchSelect
            name="municipality"
            change={setMunicipalityId}
            data={municipalities.data}
          />
          <ArrowNext active={municipalityId > 0} />
        </div>
      </div>
      <div className="h-72 inline-block w-1/5 pr-3">
        <div className="bg-lightgrey-700 border border-lightgrey-500 relative rounded-lg h-full">
          <SearchSelect
            name="location"
            change={() => console.log("Set location")}
            data={locations.data}
          />
          <ArrowNext />
        </div>
      </div>
      <div className="h-72 inline-block w-1/5 pr-3">
        <div className="bg-lightgrey-700 border border-lightgrey-500 relative rounded-lg h-full">
          <SearchSelect change={() => console.log("Set location")} />
          <ArrowNext />
        </div>
      </div>

      <div className="h-72 inline-block w-1/5 pr-3">
        <div className="bg-lightgrey-700 border border-lightgrey-500 rounded-lg h-full">
          <SearchSelect change={() => console.log("Set location")} />
        </div>
      </div>
    </div>
  );
};

export default LocationSearch;
