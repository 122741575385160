import { Form, Input } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoWhite from "../../images/logo-gobierno-blanco.png";
import logoBlack from "../../images/logo-gobierno.png";
import { signin } from "../../services/authService";
import styles from "./Auth.module.less";
import { login } from "./sessionSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState(null);
  const formField = { background: "#e7e7e7", padding: "10px 14px" };

  const handleLogin = async () => {
    const { email, password } = form.getFieldsValue();
    const response = await signin(email, password);

    if (!response.loggedIn) {
      setErrors(response.errors);
      form.resetFields();

      return;
    }

    const { Authorization: token } = response.data;
    const session = { email, token, active: true };

    dispatch(login(session));
    window.localStorage.setItem("session", JSON.stringify(session));

    navigate("/");
  };

  return (
    <div className={styles.auth}>
      <div className={styles.formSection}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleLogin}
          className={styles.form}
          requiredMark={false}
        >
          <img src={logoBlack} className="mx-auto mb-7 hidden sm:block" alt="logo" />
          <img
            src={logoWhite}
            className="mx-auto mb-7 sm:absolute sm:top-4 sm:left-4"
            alt="logo"
          />
          {errors && (
            <Form.ErrorList errors={errors} className="text-red-500 mb-3" />
          )}

          <Form.Item
            label={<label className="text-white sm:text-black">Correo:</label>}
            name="email"
            rules={[
              { required: true, message: "Este campo es necesario" },
              { type: "email", message: "El email ingresado no es válido" },
            ]}
          >
            <Input
              placeholder="Ingresa aquí tu email"
              style={formField}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={
              <label className="text-white sm:text-black">Contraseña:</label>
            }
            rules={[{ required: true, message: "Este campo es necesario" }]}
          >
            <Input.Password
              placeholder="Ingresa tu contraseña de usuario"
              style={formField}
            />
          </Form.Item>

          <Form.Item>
            <button className="bg-primary text-white rounded w-4/5 sm:w-1/2 py-2 mx-auto mt-4 text-md">
              Ingresar
            </button>
          </Form.Item>
        </Form>
        <div className="w-full absolute sm:hidden bottom-0 left-0 h-5 bg-primary" />
      </div>
      <div className={styles.imageSection}>
        <blockquote className="m-auto px-5 lg:px-0 relative font-bold text-2xl text-white w-[30ch] text-left">
          "La perfección no puede lograrse, pero mientras perseguimos esta
          perfección podemos alcanzar la excelencia"
          <span className="absolute -bottom-5 text-lg right-0 pr-5 lg:px-0">
            Vince Lombardi.
          </span>
        </blockquote>
        <div className="w-1/2 absolute bottom-0 left-0 h-5 bg-primary" />
      </div>
    </div>
  );
};

export default Login;
