import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Space } from "antd";
import { useState } from "react";
import ModalButtons from "../../components/modal/ModalButtons";
import ModalTitle from "../../components/modal/ModalTitle";
import { modalInput } from "../../styles/formFields";
import GoodForm from "../form/GoodForm";
import ServiceForm from "../form/ServiceForm";

const CreateGoodService = () => {
  const [type, setType] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);

  const handleModalClose = () => {
    setType(null);
    setModalVisible(false);
    setDisplayForm(false);
  };

  const handleNext = () => {
    if (type === null) return;

    setDisplayForm(true);
  };

  const isGood = displayForm && type === "bien";
  const isService = displayForm && type === "servicio";

  return (
    <>
      <Button type="primary" size="large" onClick={() => setModalVisible(true)}>
        <Space className="text-sm">
          <PlusCircleOutlined /> NUEVO
        </Space>
      </Button>
      <Modal
        title={<ModalTitle title="Crear nuevo" subtitle="Bien o Servicio" />}
        visible={modalVisible}
        closeIcon={<CloseCircleOutlined />}
        onCancel={handleModalClose}
        footer={null}
        width="689px"
      >
        {(type === null || !displayForm) && (
          <>
            <Select
              bordered={false}
              placeholder="Tipo"
              className="w-full"
              style={modalInput}
              value={type}
              onChange={(value) => setType(value)}
            >
              <Select.Option value="bien">Bien</Select.Option>
              <Select.Option value="servicio">Servicio</Select.Option>
            </Select>
            <ModalButtons
              onCancel={handleModalClose}
              onOk={handleNext}
              nextText="Siguiente"
              align="end"
            />
          </>
        )}

        {isGood && <GoodForm closeModal={handleModalClose} />}
        {isService && <ServiceForm closeModal={handleModalClose} />}
      </Modal>
    </>
  );
};

export default CreateGoodService;
