import { Table } from "antd";
import { useEffect } from "react";
import { useApi } from "../../../hooks/useApi";
import { removeVehicle, vehiclesIndex } from "../../../services/vehicleService";
import ActionColumn from "../../components/table/ActionColumn";
import EditVehicle from "../edit/EditVehicle";
import TableHeader from "./TableHeader";

const VehiclesTable = () => {
  const [response, setResponse] = useApi(vehiclesIndex);

  useEffect(() => {
    setResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Vehículos</h1>
      </div>
      <Table
        title={() => <TableHeader />}
        showSorterTooltip={false}
        dataSource={response.data ?? []}
        loading={response.isFetching}
        pagination={false}
        className="mt-8 px-16"
        columns={[
          {
            title: "Modelo",
            dataIndex: "vehicle_model",
            sorter: (a, b) =>
              a["vehicle_model"].name.localeCompare(b["vehicle_model"].name),
            render: (text, record) => record["vehicle_model"].name,
          },
          {
            title: "Marca",
            dataIndex: "vehicle_brand",
            filters: response.isSuccess
              ? [...new Set(response.data.map((i) => i["vehicle_brand"].name))]
                  .sort((a, b) => a.localeCompare(b))
                  .map((i) => ({ text: i, value: i }))
              : [],
            sorter: (a, b) =>
              a["vehicle_brand"].name.localeCompare(b["vehicle_brand"].name),
            onFilter: (value, record) => record["vehicle_brand"].name === value,
            render: (text, record) => record["vehicle_brand"].name,
          },
          {
            title: "Placa",
            dataIndex: "license_plate",
            sorter: (a, b) =>
              a["license_plate"].localeCompare(b["license_plate"]),
          },
          {
            title: "Año",
            dataIndex: "year",
            sorter: (a, b) => a.year.localeCompare(b.year),
          },
          {
            title: "Color",
            dataIndex: "color",
            sorter: (a, b) => a.color.localeCompare(b.color),
            filters: [
              {
                text: "Amarillo",
                value: "Amarillo",
              },
              {
                text: "Azul",
                value: "Azul",
              },
              {
                text: "Rojo",
                value: "Rojo",
              },
            ],
          },
          {
            title: "Accion",
            key: "actions",
            render: (text, record) => (
              <ActionColumn
                record={record}
                modal={<EditVehicle record={record} onSuccess={setResponse} />}
                deleteRecord={removeVehicle}
                deleteOptions={{
                  title: "Alerta",
                  subtitle: "Eliminar vehiculo",
                  message:
                    "Estimado usuario esta a punto de eliminar un vehiculo de la lista.",
                  afterDelete: setResponse,
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default VehiclesTable;
