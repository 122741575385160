import Icon from "@ant-design/icons";

const CheckSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill="#19B368" />
    <path
      d="M9.33268 3.5L4.74935 8.08333L2.66602 6"
      stroke="#FAFAFA"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CheckFilled = (props) => <Icon component={CheckSvg} {...props} />;

export default CheckFilled;
