import { Col, Form, Input, Row, Select } from "antd";
import ColorPicker from "../../components/form/ColorPicker";
import DependencySelect from "../../components/form/DependencySelect";
import { modalInput } from "../../styles/formFields";

const DependencyForm = ({ values = {}, submit, pointer }) => {
  const handleColorChange = (color) => {
    pointer.setFieldsValue({ color });
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={submit}
      form={pointer}
      className="text-left"
      colon={false}
    >
      <Form.Item
        name="name"
        initialValue={values.name ?? ""}
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Input placeholder="Nombre" style={modalInput} />
      </Form.Item>

      <Row>
        <Col span={17}>
          <Form.Item
            name="category"
            rules={[{ required: true, message: "Este campo es necesario" }]}
          >
            <Select bordered={false} placeholder="Categoría" style={modalInput}>
              <Select.Option value={1}>Dependecia</Select.Option>
              <Select.Option value={2}>Ente</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={4} offset={1}>
          <Form.Item
            name="color"
            rules={[{ required: true, message: "Este campo es necesario" }]}
          >
            <ColorPicker initialValue={values.color} onChange={handleColorChange} />
          </Form.Item>
        </Col>
      </Row>

      <DependencySelect placeholder="Subdependencia" label="" />
    </Form>
  );
};

export default DependencyForm;
