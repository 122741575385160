import styles from "./Header.module.css";
import logo from "../../logo.svg";

const Header = () => {
  return (
    <header className={styles.header}>
      <img src={logo} className={styles.logo} alt="logo" />
      <h1 className="mt-3 text-3xl">Bienvenido</h1>
    </header>
  );
};

export default Header;
