import { Table } from "antd";
import { removeDependency } from "../../../services/dependencyService";
import ActionColumn from "../../components/table/ActionColumn";
import EditDependency from "../edit/EditDependency";

const SubdependenciesTable = ({ subdependencies }) => (
  <Table
    showHeader={false}
    footer={null}
    dataSource={subdependencies}
    pagination={false}
    rowExpandable={(record) => record["sub_entities"]?.length > 0}
    expandable={{
      expandedRowRender: (record) => (
        <SubdependenciesTable subdependencies={record["sub_entities"]} />
      ),
    }}
    rowKey={(record) => record.name}
    columns={[
      { title: "Nombre", dataIndex: "name" },
      { title: "Categoria", dataIndex: "category", width: 250 },
      {
        title: "ACCIÓN",
        key: "actions",
        width: 200,
        render: (text, record) => (
          <ActionColumn
            record={record}
            deleteRecord={removeDependency}
            modal={<EditDependency record={record} />}
            deleteOptions={{
              title: "Alert",
              subtitle: "Dependencia",
              message:
                "Estimado usuario esta a punto de eliminar una dependencia, recuerde que no podra recuperar esta acción.",
              afterDelete: () => console.log("william"),
            }}
          />
        ),
      },
    ]}
  />
);

export default SubdependenciesTable;
