import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Radio, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useApi } from "../../../hooks/useApi";
import { createGood } from "../../../services/goodService";
import { createService } from "../../../services/serviceService";
import { typesOfGoodIndex } from "../../../services/typeOfGoodService";
import { typesOfServiceIndex } from "../../../services/typeOfServiceService";
import { selectSession } from "../../auth/sessionSlice";
import ModalTitle from "../../components/modal/ModalTitle";

const CreateGood = () => {
  const [form] = Form.useForm();
  const { token } = useSelector(selectSession);
  const params = useParams();
  const [type, setType] = useState("bien");
  const [goodModal, setGoodModal] = useState(false);

  const [serviceTypes, setServiceTypes] = useApi(() =>
    typesOfServiceIndex(token)
  );
  const [serviceResponse, createServices] = useApi(() =>
    createService(
      { ...form.getFieldsValue(), social_program_id: params.id },
      token
    )
  );

  const [goodTypes, setGoodTypes] = useApi(() => typesOfGoodIndex(token));
  const [goodResponse, createGoods] = useApi(() =>
    createGood(
      { ...form.getFieldsValue(), social_program_id: params.id },
      token
    )
  );

  useEffect(() => {
    if (!goodModal) return;
    if (type === "bien" && !goodTypes.data) setGoodTypes();
    if (type === "servicio" && !serviceTypes.data) setServiceTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, goodModal]);

  const handleSubmit = () => {
    if (type === "bien") createGoods();
    if (type === "servicio") createServices();
  };

  return (
    <>
      <Button type="primary" size="large" onClick={() => setGoodModal(true)}>
        <Space className="text-sm">
          <PlusCircleOutlined /> NUEVA
        </Space>
      </Button>
      <Modal
        title={<ModalTitle subtitle="Bien o Servicio" title="Crear nuevo" />}
        closeIcon={<CloseCircleOutlined />}
        onOk={handleSubmit}
        onCancel={() => setGoodModal(false)}
        visible={goodModal}
      >
        <div className="mx-auto max-w-xl">
          {goodResponse.error ||
            (serviceResponse.error && (
              <p className="text-red-500 mt-8 text-lg">
                {" "}
                {goodResponse.error || serviceResponse.error}
              </p>
            ))}

          <div className="mb-7">
            <Radio.Group
              options={[
                { label: "Bien", value: "bien" },
                { label: "Servicio", value: "servicio" },
              ]}
              onChange={({ target }) => setType(target.value)}
              value={type}
              optionType="button"
            />
          </div>

          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={handleSubmit}
            form={form}
            className="text-left mt-2 flex justify-between"
          >
            <Form.Item name="name" className="w-[48%]">
              <Input placeholder="Nombre" />
            </Form.Item>

            <Form.Item name="type_ids" className="w-[48%]">
              <Select
                mode="multiple"
                placeholder={`Tipo de ${type}`}
              >
                {type === "bien" &&
                  goodTypes.isSuccess &&
                  goodTypes.data.map((type) => (
                    <Select.Option value={type.id}>{type.name}</Select.Option>
                  ))}

                {type === "servicio" &&
                  serviceTypes.isSuccess &&
                  serviceTypes.data.map((type) => (
                    <Select.Option value={type.id}>{type.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CreateGood;
