import { CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./flashMessage.module.less";
import { removeFlash, selectFlash } from "./flashSlice";

const FlashMessage = () => {
  const { status, message, active } = useSelector(selectFlash);
  const dispatch = useDispatch();

  useEffect(() => {
    if (active) {
      setTimeout(() => handleRemoveFlash(), 6000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const handleRemoveFlash = () => {
    dispatch(removeFlash());
  };

  return (
    <div
      className={`${styles.flash} ${styles[status]} ${
        active ? styles.block : styles.hidden
      }`}
    >
      {message}
      <button
        type="button"
        onClick={handleRemoveFlash}
        className="bg-transparent ml-6"
      >
        <CloseOutlined />
      </button>
    </div>
  );
};

export default FlashMessage;
