import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.less';
import AccountSettings from './features/account/AccountSettings';
import Login from './features/auth/Login';
import { logout, selectSession } from './features/auth/sessionSlice';
import Signup from './features/auth/Signup';
import { removeFlash } from './features/components/flash/flashSlice';
import { DependenciesTable } from './features/dependencies';
import { GoodServicesTable } from './features/goods';
import { CreateGroup, EditGroup, GroupsTable } from './features/groups/';
import Header from './features/header/Header';
import { InventoryTable } from './features/inventories';
import AppLayout from './features/layout/Layout';
import { LocationsTable } from './features/locations';
import { PersonnelTable } from './features/personnel';
import { CreateProgram, ProgramsTable } from './features/programs';
import { VehiclesTable } from './features/vehicles';
import { UserTable } from './features/users';
import { loginPath, rootPath, signupPath } from './routes';
import { validateToken } from './services/authService';

function App() {
  const { active, token } = useSelector(selectSession);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!active && ![loginPath, signupPath].includes(location.pathname))
      navigate(loginPath)

    else if (active && [loginPath, signupPath].includes(location.pathname))
      navigate(rootPath);
  }, [active, location.pathname, navigate]);

  useEffect(() => {
    dispatch(removeFlash())
    validateToken(token)
      .then((data) => {

        if (!data.loggedIn) {
          localStorage.removeItem('session');
          dispatch(logout());
        }
      });
  }, [dispatch, location.pathname, token])
  
  return (
    <div className="text-center">
      <Routes>
        <Route element={<AppLayout />}>
          <Route path={rootPath} element={<Header />} />
          <Route path="programas">
            <Route path="nuevo" element={<CreateProgram />} />
            <Route index element={<ProgramsTable />} />
          </Route>
          <Route path="grupos">
            <Route path="nuevo" element={<CreateGroup />} />
            <Route path=":id" element={<EditGroup />} />
            <Route index element={<GroupsTable />} />
          </Route>
          <Route path="personal">
            <Route index element={<PersonnelTable />} />
          </Route>
          <Route path="vehiculos">
            <Route index element={<VehiclesTable />} />
          </Route>
          <Route path="inventarios" element={<InventoryTable />} />
          <Route path="dependencias" element={<DependenciesTable />} />
          <Route path="ubicaciones" element={<LocationsTable />} />
          <Route path="bienes-servicios" element={<GoodServicesTable />} />
          <Route path="usuarios" element={<UserTable />} />
          <Route path="cuenta">
            <Route path="ajustes" element={<AccountSettings />} />
          </Route>
        </Route>
        <Route path="auth" >
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
