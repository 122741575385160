import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import sessionReducer from '../features/auth/sessionSlice'
import groupReducer from '../features/programs/form/GroupCreation/groupSlice';
import flashReducer from '../features/components/flash/flashSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    session: sessionReducer,
    groups: groupReducer,
    flash: flashReducer
  },
});
