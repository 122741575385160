import { Form, Input, Select } from "antd";

const UserForm = ({ values = {}, submit, pointer }) => {
  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={submit}
      form={pointer}
      className="text-left"
    >
      <Form.Item
        label="Rol del Usuario"
        name="rol"
        initialValue={values.rol ?? null}
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Select placeholder="Rol de usuario">
          <Select.Option value={1}>Admin</Select.Option>
          <Select.Option value={2}>Coordinador</Select.Option>
          <Select.Option value={3}>Brigadista</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Nombre Completo"
        name="name"
        initialValue={values.name ?? ""}
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Input placeholder="Ingresa nombre y apellido" />
      </Form.Item>

      <Form.Item
        label="Correo electrónico"
        name="email"
        initialValue={values.email ?? ""}
        rules={[
          { required: true, message: "Este campo es necesario" },
          { type: "email", message: "El email ingresado no es válido" },
        ]}
      >
        <Input placeholder="Ingresa el correo electrónico" />
      </Form.Item>

      <Form.Item
        label="Contraseña"
        name="password"
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Input.Password placeholder="Ingresa tu contraseña" />
      </Form.Item>
    </Form>
  );
};

export default UserForm;
