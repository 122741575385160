import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  status: "",
  active: false,
};

export const flashSlice = createSlice({
  name: "flash",
  initialState,

  reducers: {
    addFlash: (state, action) => {
      const { status, message } = action.payload;

      return {
        status,
        message,
        active: true,
      };
    },
    removeFlash: (state) => initialState,
  },
});

export const { addFlash, removeFlash } = flashSlice.actions;

export const selectFlash = (state) => state.flash;

export default flashSlice.reducer;
