import { EditFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { useState } from "react";
import ModalTitle from "../../components/modal/ModalTitle";
import GoodForm from "../form/GoodForm";
import ServiceForm from "../form/ServiceForm";

const EditGoodService = ({ type, values }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button type="text" size="small" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <EditFilled style={{ color: "rgb(0, 208, 208)" }} />
        </Space>
      </Button>

      <Modal
        title={<ModalTitle title="Editar" subtitle="Item de inventario" />}
        closeIcon={<CloseCircleOutlined />}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width="689px"
      >
        {type === "Bien" && <GoodForm values={values} />}

        {type === "Servicio" && <ServiceForm values={values} />}
      </Modal>
    </>
  );
};

export default EditGoodService;
