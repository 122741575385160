import { useState } from "react";

export const useApi = (apiFunction) => {
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
    isSuccess: false,
  });

  const fetchMethod = () => {
    setResponse({
      data: null,
      isFetching: true,
      error: null,
      isSuccess: false,
    });

    apiFunction()
      .then((res) => {
        setResponse({
          ...response,
          data: res.data,
          isFetching: false,
          isSuccess: true,
          error: null,
        });
      })
      .catch((err) => {
        if (err.response) {
          setResponse({
            ...response,
            isFetching: false,
            error: err.response.data,
          });
        } else {
          setResponse({
            ...response,
            isFetching: false,
            error: err.message,
          });
        }
      });
  };

  return [response, fetchMethod];
};
