import { Form } from "antd";
import GroupForm from '../form/GroupForm';

const EditGroup = () => {
  const [form] = Form.useForm();

  return (
    <div className="sm:w-8/12 mx-auto p-7 max-w-xl">
      <h1 className="text-2xl my-4">Editar Grupo</h1>
      <GroupForm submit={() => console.log("Enviando...")} pointer={form} />
    </div>
  );
};

export default EditGroup;
