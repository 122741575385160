import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { Link } from "react-router-dom";
import { newProgram } from "../../../routes";

const TableHeader = ({ data }) => {
  return (
    <div className="bg-slate-100 px-7 py-6">
      <div className="w-1/4 rounded-md mb-6" >
        <Input.Search placeholder="Buscar programas" type="search" />
      </div>
      <div className="flex justify-between items-center">
        <div className="h-12">
          <span className="text-zinc-500">Programas totales</span>
          <p className="text-lg font-semibold">{data.length} programas</p>
        </div>
        <Button size="large">
          <Link to={newProgram} className="text-sm">
            <Space>
              <PlusCircleOutlined /> NUEVO
            </Space>
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
