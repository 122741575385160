import { DatePicker, Form, Tabs } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import DependencySelect from "../../components/form/DependencySelect";
import GoodsAndServices from "../../components/form/GoodsAndServices";
import LocationSearch from "../../components/form/LocationSearch";
import GroupCreation from "./GroupCreation/GroupCreation";
import ProgramName from "./ProgramName";

const FormTabs = ({ activeTab, values = {} }) => (
  <Tabs activeKey={`tab${activeTab}`} animated renderTabBar={() => ""}>
    <Tabs.TabPane key="tab0">
      <h2 className="text-center text-2xl my-14 text-lightgrey">
        Elegir dependencia o ente que organiza el programa
      </h2>
      <DependencySelect className="mx-auto w-5/12" />
    </Tabs.TabPane>
    <Tabs.TabPane key="tab1">
      <div className="mx-auto w-5/12">
        <h2 className="text-center text-2xl my-14 text-lightgrey">
          Determinar duración del programa
        </h2>
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="dates"
          initialValue={
            values["start_date"] && [
              moment(values["start_date"]),
              moment(values["finish_date"]),
            ]
          }
          rules={[{ required: true, message: "Este campo es necesario" }]}
        >
          <DatePicker.RangePicker
            locale={locale}
            className="w-full"
            placeholder={["Comienza", "Finaliza"]}
            showTime={{ format: "h:mm", minuteStep: 5 }}
            placement="bottomLeft"
          />
        </Form.Item>
      </div>
    </Tabs.TabPane>
    <Tabs.TabPane key="tab2">
      <h2 className="text-center text-2xl my-14 text-lightgrey">
        Selección de Bienes y Servicios
      </h2>
      <GoodsAndServices />
    </Tabs.TabPane>
    <Tabs.TabPane key="tab3">
      <div className="mx-auto">
        <h2 className="text-center text-2xl my-4 text-lightgrey">
          Organización de grupos de trabajo
        </h2>
        <GroupCreation />
      </div>
    </Tabs.TabPane>
    <Tabs.TabPane key="tab4">
      <div className="mx-auto">
        <h2 className="text-center text-2xl my-4 text-lightgrey">
          Organización de grupos de trabajo
        </h2>
        <GroupCreation assignment />
      </div>
    </Tabs.TabPane>
    <Tabs.TabPane key="tab5">
      <div className="mx-auto w-5/12">
        <h2 className="text-center text-2xl my-14 text-lightgrey">
          Definir nombre de programa
        </h2>
        <ProgramName
          values={{
            name: values.name,
            precomposition: values.precomposition,
          }}
        />
      </div>
    </Tabs.TabPane>
    <Tabs.TabPane key="tab6">
      <LocationSearch />
    </Tabs.TabPane>
  </Tabs>
);

export default FormTabs;
