import { Table } from "antd";
import { useEffect } from "react";
import { EditDependency } from "../";
import { useApi } from "../../../hooks/useApi";
import {
  dependenciesIndex,
  removeDependency,
} from "../../../services/dependencyService";
import ActionColumn from "../../components/table/ActionColumn";
import SubdependenciesTable from "./SubdependenciesTable";
import TableHeader from "./TableHeader";

const DependenciesTable = () => {
  const [response, setResponse] = useApi(dependenciesIndex);

  useEffect(() => {
    setResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Dependencias</h1>
      </div>
      <Table
        title={() => <TableHeader data={response.data ?? []} />}
        dataSource={response.data ?? []}
        loading={response.isFetching}
        pagination={false}
        rowKey={(record) => record.name}
        className="mt-12 px-16"
        rowExpandable={(record) => record["sub_entities"].length > 0}
        expandable={{
          expandedRowRender: (record) => (
            <SubdependenciesTable subdependencies={record["sub_entities"]} />
          ),
        }}
        columns={[
          { title: "DEPENDENCIA", dataIndex: "name" },
          { title: "CATEGORÍA", dataIndex: "category", width: 250 },
          {
            title: "ACCIÓN",
            width: 200,
            key: "actions",
            render: (text, record) => (
              <ActionColumn
                record={record}
                deleteRecord={removeDependency}
                modal={<EditDependency record={record} />}
                deleteOptions={{
                  title: "Alert",
                  subtitle: "Dependencia",
                  message:
                    "Estimado usuario esta a punto de eliminar una dependencia, recuerde que no podra recuperar esta acción.",
                  afterDelete: () => setResponse(),
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default DependenciesTable;
