import {
  DeliveredProcedureOutlined,
  DesktopOutlined,
  PieChartOutlined,
  ReconciliationOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Input, Layout, Menu, Space } from "antd";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import {
  dependenciesIndex,
  goodServicesIndex,
  groupsIndex,
  inventoriesIndex,
  locationsIndex,
  personnelIndex,
  programsIndex,
  rootPath,
  userIndex,
  vehiclesIndex,
} from "../../routes";
import FlashMessage from "../components/flash/FlashMessage";
import UserOptions from "../components/UserOptions";
import styles from "./Layout.module.less";
const { Header, Content, Sider } = Layout;
const { Item, SubMenu } = Menu;

const AppLayout = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <FlashMessage />
    <Sider collapsible className={styles.sider} theme="light">
      <h1 className="m-[1.4rem] font-bold">LOGO</h1>
      <Menu mode="inline" className={styles.menu}>
        <SubMenu key="sub0" icon={<DesktopOutlined />} title="Programas">
          <Item key="Programas">
            <Link to={programsIndex}>Listado general</Link>
          </Item>
          <Item key="Activos">
            <Link to={programsIndex}>Programas activos</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub1"
          icon={<ReconciliationOutlined />}
          title="Inventario"
        >
          <Item key="General">
            <Link to={inventoriesIndex}>General</Link>
          </Item>
          <Item key="Dependencia">
            <Link to={dependenciesIndex}>Dependencia</Link>
          </Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<PieChartOutlined />} title="Operatividad">
          <Item key="Operatividad">
            <Link to={personnelIndex}>Brigadista</Link>
          </Item>
          <Item key="Vehiculos">
            <Link to={vehiclesIndex}>Vehiculos</Link>
          </Item>
        </SubMenu>
        <Item
          className={styles.menuitem}
          key="Seguimiento"
          icon={<DeliveredProcedureOutlined />}
        >
          <Link to={rootPath}>Seguimiento</Link>
        </Item>
        <SubMenu key="sub3" icon={<UnorderedListOutlined />} title="Listados">
          <Item key="Dependencias">
            <Link to={dependenciesIndex}>Dependencias</Link>
          </Item>
          <Item key="bienesservicios">
            <Link to={goodServicesIndex}>Bienes/Servicios</Link>
          </Item>
          <Item key="Usuarios">
            <Link to={userIndex}>Usuarios</Link>
          </Item>
          <Item key="Grupos">
            <Link to={groupsIndex}>Grupos</Link>
          </Item>
          <Item key="Composiciones">
            <Link to={rootPath}>Programas</Link>
          </Item>
          <Item key="Ubicaciones">
            <Link to={locationsIndex}>Ubicaciones</Link>
          </Item>
        </SubMenu>
      </Menu>
    </Sider>
    <Layout className="site-layout">
      <Header className={styles.header}>
        <Space size="large">
          <Input placeholder="Buscar" prefix={<SearchOutlined />} />
          <span>Español</span>
          <UserOptions />
        </Space>
      </Header>
      <Content className="h-full overflow-y-auto bg-slate-50">
        <Outlet />
      </Content>
    </Layout>
  </Layout>
);

export default AppLayout;
