import { useEffect, useRef, useState } from "react";

const ColorPicker = ({ onChange, initialValue = "#00D0D0" }) => {
  const [color, setColor] = useState(initialValue);
  const input = useRef();

  const handleClick = () => {
    input.current.click();
  };

  useEffect(() => {
    onChange(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <>
      <label className="bg-modal-input border border-lightgrey-500 ant-select-selection-placeholder rounded-lg px-3 py-2 rounded-r-none">
        Color
        <input
          type="color"
          name="color"
          className="opacity-0 absolute"
          ref={input}
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </label>
      <span
        onClick={handleClick}
        style={{ backgroundColor: color }}
        className="py-2 px-7 border border-l-0 rounded-r-lg"
      ></span>
    </>
  );
};

export default ColorPicker;
