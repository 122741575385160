import axios from 'axios';

export const rootPath = '/';

// Dependencies
export const dependenciesIndex = '/dependencias';
export const showDependency = (id) => `${dependenciesIndex}/${id}`;

// Programs
export const programsIndex = '/programas'
export const newProgram = '/programas/nuevo'

// Groups
export const groupsIndex = '/grupos'
export const newGroup = '/grupos/nuevo'
export const showGroup = (id) => `${groupsIndex}/${id}`;

// Personnel
export const personnelIndex = '/personal'

// Vehicles
export const vehiclesIndex = '/vehiculos'

// Goods & Servicies
export const goodServicesIndex = "/bienes-servicios";

// Inventory
export const inventoriesIndex =  `/inventarios`;
export const showInventory = (id) =>  `${inventoriesIndex}/${id}`;

// Locations
export const locationsIndex =  `/ubicaciones`;

// Account
export const accountSettings = `/cuenta/ajustes`;

// Users
export const userIndex = '/usuarios';
export const newUser = '/usuarios/nuevo';
export const showUser = (id) => `${userIndex}/${id}`;

// Auth
export const loginPath = "/auth/login"
export const signupPath = "/auth/signup"

// Api
export const apiBase = 'https://api.social-p.xyz';

// Axios config
axios.defaults.baseURL = `${apiBase}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';