import { CloseCircleOutlined, EditFilled } from "@ant-design/icons";
import { Button, Form, Modal, Space } from "antd";
import { useState } from "react";
import ModalTitle from "../../components/modal/ModalTitle";
import LocationForm from "../form/LocationForm";

const EditLocation = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  return (
    <>
      <Button type="text" size="small" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <EditFilled style={{ color: "rgb(0, 208, 208)" }} />
        </Space>
      </Button>
      <Modal
        title={<ModalTitle title="Editar" subtitle="Ubicación" />}
        closeIcon={<CloseCircleOutlined />}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <LocationForm
          values={record}
          submit={() => console.log("Editando ubicacion...")}
          pointer={form}
        />
      </Modal>
    </>
  );
};

export default EditLocation;
