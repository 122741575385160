import { Form, Input, Button, Select, InputNumber } from "antd";

const GroupForm = ({ values = {}, submit, pointer }) => {
  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={submit}
      form={pointer}
      className="text-left"
    >
      <Form.Item
        label="Nombre"
        name="name"
        initialValue={values.name ?? ""}
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Input placeholder="Nombre del grupo" />
      </Form.Item>

      <Form.Item
        label="Dependencia"
        name="dependency"
        initialValue={values.dependency ?? null}
        rules={[{ required: true, message: "Por favor seleccione una dependencia" }]}
      >
        <Select
          placeholder="Dependencia del grupo de trabajo"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value="1">Salud</Select.Option>
          <Select.Option value="2">Deporte</Select.Option>
          <Select.Option value="3">Bienestar</Select.Option>
          <Select.Option value="4">Agricultura</Select.Option>
          <Select.Option value="5">Ganaderia</Select.Option>
        </Select>
      </Form.Item>

      <div className="flex">
        <Form.Item
          label="Coordinadores"
        >
          <InputNumber defaultValue={1} min={0} />
        </Form.Item>
        <Form.Item
          label="Jefes de brigada"
        >
          <InputNumber defaultValue={1} min={0} />
        </Form.Item>
        <Form.Item
          label="Brigadistas"
        >
          <InputNumber defaultValue={1} min={0} />
        </Form.Item>
      </div>

      <Form.Item className="text-left">
        <Button type="primary" htmlType="submit" className="w-6/12 mt-4">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GroupForm;
