import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { createPersonnel } from "../../../services/personnelService";
import { selectSession } from "../../auth/sessionSlice";
import { addFlash } from "../../components/flash/flashSlice";
import ModalButtons from "../../components/modal/ModalButtons";
import ModalTitle from "../../components/modal/ModalTitle";
import PersonnelForm from "../form/PersonnelForm";

const CreatePersonnel = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { token } = useSelector(selectSession);
  const [response, setResponse] = useApi(() =>
    createPersonnel(form.getFieldsValue(), token)
  );
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (response.isSuccess && !response.error) {
      dispatch(
        addFlash({
          message: "Se ha creado el brigadista satisfactoriamente ✅",
          status: "success",
        })
      );
      handleCloseModal();
    } else if (response.error) {
      dispatch(
        addFlash({
          message:
            "Hubo problemas con la creacion del brigadista, verificar y volver crear🚨",
          status: "error",
        })
      );
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <>
      <Button type="primary" size="large" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <PlusCircleOutlined /> NUEVO
        </Space>
      </Button>
      <Modal
        title={<ModalTitle title="Crear nuevo" subtitle="Personal" />}
        closeIcon={<CloseCircleOutlined />}
        width="689px"
        visible={visible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <PersonnelForm pointer={form} submit={setResponse} />
        <ModalButtons
          onCancel={handleCloseModal}
          onOk={setResponse}
          nextText="Guardar"
          align="right"
        />
      </Modal>
    </>
  );
};

export default CreatePersonnel;
