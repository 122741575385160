import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space } from "antd";
import CreateGood from "../create/CreateGood";

const TableHeader = ({ data }) => {
  return (
    <div className="bg-slate-100 px-7 py-6">
      <div className="w-5/12 mb-7 flex">
        <Input
          suffix={<SearchOutlined />}
          className="mr-5"
          placeholder="Buscar"
          type="search"
        />
        <div className="text-left w-full">
          <Select placeholder="Tipo" className="w-full">
            <Select.Option value="cms">Bien</Select.Option>
            <Select.Option value="app">Servicio</Select.Option>
          </Select>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between w-1/4">
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total bienes</span>
            <p className="text-lg font-semibold">
              {data.filter((i) => i.type === "Bien").length} bienes
            </p>
          </div>
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total servicios</span>
            <p className="text-lg font-semibold">
              {data.filter((i) => i.type === "Servicio").length} servicios
            </p>
          </div>
        </div>
        <div>
          <Button size="large" className="mr-5">
            <Space className="text-sm">
              <UploadOutlined /> CARGAR .CSV
            </Space>
          </Button>
          <CreateGood />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
