import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { useState } from "react";
import ModalButtons from "../../components/modal/ModalButtons";
import GoodFields from "./GoodFields";
import EvidenceFields from "./EvidenceFields";

const GoodForm = ({ closeModal, values = {} }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);

  return (
    <>
      <Form layout="vertical" form={form}>
        {step === 1 && <GoodFields values={values} />}
        {step === 2 && <EvidenceFields />}

        <div className="flex justify-between items-center">
          <div className="flex items-center mt-4">
            <button
              type="button"
              className="rounded w-11 h-11"
              disabled={step === 1}
              onClick={() => setStep(1)}
            >
              <ArrowLeftOutlined />
            </button>
            <button
              type="button"
              className="rounded w-11 h-11 ml-3"
              disabled={step === 2}
              onClick={() => setStep(2)}
            >
              <ArrowRightOutlined />
            </button>
          </div>

          <Form.Item>
            <ModalButtons
              onOk={() => {
                form
                  .validateFields()
                  .then(() => {
                    if (step === 1) {
                      setStep(2);
                      return;
                    }

                    closeModal();
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
              onCancel={closeModal}
              nextText={step === 1 ? "Siguiente" : "Guardar"}
              align="end"
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default GoodForm;
