import { CarryOutOutlined, HourglassOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { usersIndex } from "../../../services/userService";
import ActionColumn from "../../components/table/ActionColumn";
import EditUser from "../edit/EditUser";
import TableHeader from "./TableHeader";

const dataApp = [
  { name: "Usuario app 1", id: 1, email: "app_user1@admin.com", role: "Admin" },
  {
    name: "Usuario app 2",
    id: 2,
    email: "app_user2@brigadista.com",
    rol: "Brigadista",
  },
];

const UserTable = () => {
  const [userType, setUserType] = useState("cms");
  const [cmsUsers, setCmsUsers] = useApi(usersIndex);

  useEffect(() => {
    setCmsUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Gestion de usuarios</h1>
      </div>
      <Table
        title={() => (
          <TableHeader
            users={{ cms: cmsUsers.data?.length }}
            setType={setUserType}
          />
        )}
        dataSource={userType === "cms" ? cmsUsers.data : dataApp}
        loading={false}
        pagination={false}
        className="mt-8 px-16"
        columns={[
          { title: "Nombre completo", dataIndex: "name" },
          { title: "Correo", dataIndex: "email" },
          {
            title: "Rol",
            dataIndex: "rol",
            filters: [
              {
                text: "Admin",
                value: "Administrador",
              },
              {
                text: "Coordinador",
                value: "Coordinador",
              },
              {
                text: "Brigadista",
                value: "Brigadista",
              },
            ],
            onFilter: (value, record) => record.rol === value,
          },
          {
            title: "Asignado",
            dataIndex: "assigned",
            render: (text, record) =>
              record.role !== "Admin" && (
                <span>
                  {record.assigned ? (
                    <CarryOutOutlined />
                  ) : (
                    <HourglassOutlined />
                  )}
                </span>
              ),
          },
          {
            title: "Opciones",
            key: "actions",
            render: (text, record) => (
              <ActionColumn
                record={record}
                modal={<EditUser onSuccess={setCmsUsers} record={record} />}
                deleteRecord={() => `borrando usuario #${record.id}`}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default UserTable;
