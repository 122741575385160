import Icon from '@ant-design/icons';

const UserSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.83312 0.88916C9.95292 0.88916 11.0269 1.334 11.8187 2.12582C12.6105 2.91764 13.0553 3.99158 13.0553 5.11138C13.0553 6.23118 12.6105 7.30512 11.8187 8.09694C11.0269 8.88876 9.95292 9.3336 8.83312 9.3336C7.71331 9.3336 6.63937 8.88876 5.84755 8.09694C5.05573 7.30512 4.61089 6.23118 4.61089 5.11138C4.61089 3.99158 5.05573 2.91764 5.84755 2.12582C6.63937 1.334 7.71331 0.88916 8.83312 0.88916ZM8.83312 11.4447C13.4987 11.4447 17.2776 13.3342 17.2776 15.6669V17.778H0.388672V15.6669C0.388672 13.3342 4.16756 11.4447 8.83312 11.4447Z"
      fill="#9197B3"
    />
  </svg>
);

const UserFilled = props => <Icon component={UserSvg} {...props} />

export default UserFilled;
