import { Button } from "antd";
import PropTypes from "prop-types";
import styles from "./Steps.module.less";

const Steps = ({ currentStep, allowedSteps, stepClick }) => {
  const titles = [
    "Quién lo organiza",
    "Duración",
    "Bien y/o Servicio",
    "Grupo de Trabajo",
    "Asignación de Usuarios",
    "Nombre del programa",
    "Lugar de aplicación",
  ];

  return (
    <>
      <div className="flex justify-between items-end pb-4 mb-5 border-b">
        <div className="text-left">
          <span className="text-sm text-lightgrey">Progreso de creación</span>
          <p className="text-lg font-semibold">Programas sociales</p>
        </div>
        <h3 className="text-lightgrey text-2xl">{titles[currentStep]}</h3>
      </div>
      <div className="max-w-6xl mx-auto">
        <div className={styles.steps}>
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <>
              <Button
                type="text"
                key={`step-${i}`}
                onClick={() => stepClick(i -1)}
                disabled={allowedSteps < i -1}
                className={`
                  ${styles.step}
                  ${i - 1 <= currentStep && styles.active}
                `}
              >
                {i}
              </Button>
              {i < 7 && (
                <span
                  className={`
                    ${styles.divider} 
                    ${i - 1 < currentStep && styles.active}
                  `}
                />
              )}
            </>
          ))}
        </div>
        <span>Paso {currentStep + 1} de 7</span>
      </div>
    </>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepClick: PropTypes.func.isRequired,
  allowedSteps: PropTypes.func.isRequired,
};


export default Steps;
