import { Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./GroupCreation.module.css";
import GroupCard from "./GroupCard";
import { useDispatch, useSelector } from "react-redux";
import { addGroup, selectGroups } from "./groupSlice";

const GroupCreation = ({ assignment }) => {
  const groups = useSelector(selectGroups);
  const dispatch = useDispatch();

  const handleGroupAdd = () => {
    dispatch(addGroup());
  };

  return (
    <div className="border rounded px-8 py-6 w-full mb-5">
      {Object.keys(groups).map(id => (
        <GroupCard {...groups[id]} assignment={assignment} />
      ))}

      <div className="flex justify-between mt-5">
        <Space size="middle">
          <span className={`${styles.userTags} ${styles.coordinador}`}>
            Coordinador
          </span>
          <span className={`${styles.userTags} ${styles.jefe}`}>
            Jefe de Brigada
          </span>
          <span className={`${styles.userTags} ${styles.brigadista}`}>
            Brigadista
          </span>
        </Space>
        <button
          type="button"
          className="bg-transparent min-w-max"
          onClick={handleGroupAdd}
        >
          <Space size="middle" className="text-lightgrey-100">
            <span className="border border-lightgrey-100 border-dashed rounded-full p-2 font-semibold">
              <PlusOutlined style={{ color: "#888888" }} />
            </span>
            AÑADIR GRUPO
          </Space>
        </button>
      </div>
    </div>
  );
};

export default GroupCreation;
