import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { precompositionsIndex } from "../../../services/precompositionService";
import { selectSession } from "../../auth/sessionSlice";
import { colors, secondaryInput } from "../../styles/formFields";

const ProgramName = ({ values = {} }) => {
  const { Option } = Select;
  const { token } = useSelector(selectSession);
  const [precomps, setPrecomps] = useApi(() => precompositionsIndex(token));
  const [preview, setPreview] = useState({ ...values });

  const handleSearch = (input, option) => {
    const term = new RegExp(`${input}.*$`, "i");

    return option.search(term) !== -1;
  };

  const handleChange = (value, type) => {
    setPreview({ ...preview, [type]: value });
  };

  useEffect(() => {
    if (!precomps.data) setPrecomps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-9/12 mx-auto">
        <Form.Item
          name="precomposition"
          initialValue={values.precomposition}
          rules={[
            { required: true, message: "Selecciona una pre-composición" },
          ]}
        >
          <Select
            showSearch
            style={{...secondaryInput, paddingLeft: "21px"}}
            bordered={false}
            placeholder="Buscar pre-composición"
            filterOption={(input, option) =>
              handleSearch(input, option.children)
            }
            onChange={(value, option) =>
              handleChange(option.children, "precomposition")
            }
          >
            {precomps.isSuccess &&
              precomps.data.map((i) => <Option value={i.id}>{i.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          initialValue={values.name ?? ""}
          rules={[{ required: true, message: "Agrega una composición" }]}
          onChange={(e) => handleChange(e.target.value, "name")}
        >
          <Input placeholder="Escribir composición" style={secondaryInput} />
        </Form.Item>
      </div>

      <div className="border-t pt-6">
        <p className="mb-2 text-center text-xs text-lightgrey-200">
          VISTA PREVIA DE NOMBRE
        </p>

        <div className="text-center bg-lightgrey-700 py-1 rounded">
          <Space size="middle">
            {preview.precomposition ?? "pre-composición"}
            <PlusOutlined style={{ color: colors.primary }} />
            {preview.name ?? "composición"}
          </Space>
        </div>
      </div>
    </>
  );
};

export default ProgramName;
