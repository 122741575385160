import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../../services/authService';
import styles from './Auth.module.less';
import { login } from './sessionSlice';
import { Form, Input, Button } from 'antd';

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const { email, password } = form.getFieldsValue();
    const response = await register(email, password);
    
    if (!response.loggedIn) {
      setIsLoading(false);
      setErrors(response.errors);
      form.resetFields();
      
      return
    };

    const { Authorization: token } = response.data;
    const session = { email, token, active: true };

    dispatch(login(session));
    window.localStorage.setItem('session', JSON.stringify(session))

    navigate('/counter')
  };

  return (
    <div className={styles.auth}>
      <h1 className={styles.title}>Crear cuenta</h1>
      {errors && (
        <Form.ErrorList errors={errors} className="mb-4 text-red-500" />
      )}
      <Form
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Correo"
          name="email"
          rules={[
            { required: true, message: 'Este campo es necesario' },
            { type: 'email', message: 'El email ingresado no es válido' }
          ]}
        >
          <Input placeholder="Ingresa tu email" />
        </Form.Item>

        <Form.Item
          label="Contraseña"
          name="password"
          rules={[
            { required: true, message: 'Este campo es necesario' },
            {min: 8, message: 'Mínimo 8 caracteres'}
          ]}
        >
          <Input.Password placeholder="Ingresa tu contraseña" />
        </Form.Item>

        <Form.Item
        name="confirm_password"
        label="Confirmar"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Este campo es necesario',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) return Promise.resolve();

              return Promise.reject(new Error('Ambas contraseñas deben coincidir'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirma tu contraseña" />
      </Form.Item>

        <Form.Item wrapperCol={{sm: {span: 12, offset: 6 }}}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Enviar
          </Button>
        </Form.Item>
      </Form>
      <Link to="/auth/login">Iniciar sesión</Link>
    </div>
  )
};

export default Signup;
