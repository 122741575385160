import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/entities";

export const createDependency = (model) =>
  apiProvider.post(baseURL, {
    name: model.name,
    color: model.color,
    parent_entity_id: model.entity,
  });
  
  export const editDependency = (model) =>
  apiProvider.patch(baseURL, {
    name: model.name,
    color: model.color,
    parent_entity_id: model.entity,
    id: model.id,
  });

export const dependenciesIndex = () => apiProvider.getAll(baseURL);

export const dependencyShow = (id) => apiProvider.getSingle(baseURL, id);

export const removeDependency = (id) => apiProvider.remove(baseURL, id);
