import { Button, Form, Input, Select } from "antd";
import { modalInput } from "../../styles/formFields";

const LocationForm = ({ values = {}, submit, pointer }) => {
  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={submit}
      form={pointer}
      className="text-left"
    >
      <Form.Item
        name="name"
        initialValue={values.name ?? ""}
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Input placeholder="Nombre" style={modalInput} />
      </Form.Item>

      <Form.Item
        name="category"
        initialValue={values.type}
        rules={[{ required: true, message: "Este campo es necesario" }]}
      >
        <Select style={modalInput} bordered={false} placeholder="Categoría">
          <Select.Option>Estado</Select.Option>
          <Select.Option>Municipio</Select.Option>
          <Select.Option>Ciudad</Select.Option>
          <Select.Option>Junta auxiliar</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LocationForm;
