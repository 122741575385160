import { Cascader, Form } from "antd";

const data = [
  {
    value: 'Bienes',
    label: 'Bienes',
    children: [
      {
        value: '1',
        label: 'Nombre de bien 1',
      },
      {
        value: '2',
        label: 'Nombre de bien 2',
      },
      {
        value: '3',
        label: 'Nombre de bien 3',
      },
      {
        value: '4',
        label: 'Nombre de bien 4',
      },
      {
        value: '5',
        label: 'Nombre de bien 5',
      },
      {
        value: '6',
        label: 'Nombre de bien 6',
      },
    ]
  },
  {
    value: 'Servicios',
    label: 'Servicios',
    children: [
      {
        value: '1',
        label: 'Nombre de servicio 1',
      },
      {
        value: '2',
        label: 'Nombre de servicio 2',
      },
      {
        value: '3',
        label: 'Nombre de servicio 3',
      },
      {
        value: '4',
        label: 'Nombre de servicio 4',
      },
      {
        value: '5',
        label: 'Nombre de servicio 5',
      },
      {
        value: '6',
        label: 'Nombre de servicio 6',
      },
    ]
  }
]

const GoodsAndServices = () => {
  return (
    <div className="w-7/12 mx-auto">
      <Form.Item name="goods_and_services">
        <Cascader
          options={data}
          multiple
          onChange={(value, selectedOptions) =>
            console.log(value, selectedOptions)
          }
          dropdownClassName="bg-red-500"
          placeholder="Elegir bienes y/o servicios"
          showSearch={(inputValue, path) =>
            path.some(
              (option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                -1
            )
          }
          onSearch={(value) => console.log(value)}
        />
      </Form.Item>
    </div>
  );
};

export default GoodsAndServices;
