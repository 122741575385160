import { apiProvider } from './api/provider';
const baseURL = '/api/v1/social_program_inventories';

export const createProgramInventory = (model) => apiProvider.post(
  baseURL,
  {
    good_id: model.entity_id,
    inventory_id: model.inventory_id,
    available: model.available,
  }
);

export const programInventoriesIndex = () => apiProvider.getAll(baseURL);

export const programInventoryShow = (id) => apiProvider.getSingle(baseURL, id);
