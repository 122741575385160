import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/users";

export const editUser = (model) =>
  apiProvider.put(baseURL, {
    id: model.id,
    password: model.password,
    rol_id: model.rol,
  });

export const usersIndex = () => apiProvider.getAll(baseURL);
