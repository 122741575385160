import axios from "axios";

const addHeaders = (headers) => {
  const session = JSON.parse(window.localStorage.getItem("session"));

  return { headers: { Authorization: session.token, ...headers } };
};

const getAll = (resource) => axios.get(resource, addHeaders());

const getSingle = (resource, id) =>
  axios.get(`${resource}/${id}`, addHeaders());

const post = (resource, model) => axios.post(resource, model, addHeaders());

const put = (resource, model) =>
  axios.put(`${resource}/${model.id}`, model, addHeaders());

const patch = (resource, model) =>
  axios.patch(`${resource}/${model.id}`, model, addHeaders());

const remove = (resource, id) =>
  axios.delete(`${resource}/${id}`, addHeaders());

export const importCsv = (resource, name, file) => {
  const formData = new FormData();
  formData.append(name, file);

  return axios.post(
    `/api/v1/${resource}/import`,
    formData,
    addHeaders({ "Content-Type": "multipart/form-data" })
  );
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};
