import { PlusOutlined } from "@ant-design/icons";
import { Form, Image } from "antd";
import { useState } from "react";
import { useRef } from "react";

const PhotoUpload = ({ name, width, height }) => {
  const input = useRef();
  const [preview, setPreview] = useState();

  const handleFileSelect = (e) => {
    console.log(e.target.files);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <Form.Item name={name} style={{display: "none"}}>
        <input type="file" ref={input} onChange={(e) => handleFileSelect(e)} />
      </Form.Item>

      {!preview && (
        <button
          type="button"
          style={{width: width, height: height}}
          className="border border-gray-400 border-dashed bg-gray-100"
          onClick={() => input.current.click()}
        >
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Cargar Foto</div>
        </button>
      )}

      {preview && (
        <Image width={width} height={height} src={preview} />
      )}
    </>
  );
};

export default PhotoUpload;
