import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { createDependency } from "../../../services/dependencyService";
import ModalTitle from "../../components/modal/ModalTitle";
import ModalButtons from "../../components/modal/ModalButtons";
import DependencyForm from "../form/DependencyForm";

const CreateDependency = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const [response, setResponse] = useApi(() =>
    createDependency(form.getFieldsValue())
  );

  useEffect(() => {
    if (response.isSuccess && !response.error) {
      window.location.reload();
    }
  }, [response]);

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button type="primary" size="large" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <PlusCircleOutlined /> NUEVA
        </Space>
      </Button>
      <Modal
        title={<ModalTitle subtitle="Dependencia" />}
        closeIcon={<CloseCircleOutlined />}
        visible={visible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <DependencyForm submit={() => setResponse()} pointer={form} />

        <ModalButtons
          align="right"
          onCancel={handleCloseModal}
          onOk={setResponse}
          nextText="Crear"
        />
      </Modal>
    </>
  );
};

export default CreateDependency;
