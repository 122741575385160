import { apiProvider } from "./api/provider";
const baseURL = "/api/v1/goods";

export const createGood = (model) =>
  apiProvider.post(baseURL, {
    good: {
      name: model.name,
      social_program_id: model.social_program_id,
      type_of_good_ids: model.type_ids,
    },
  });

export const editGood = (model) =>
  apiProvider.patch(baseURL, {
    id: model.id,
    name: model.name,
    social_program_id: model.social_program_id,
  });

export const goodsIndex = () => apiProvider.getAll(baseURL);

export const goodShow = (id) => apiProvider.getSingle(baseURL, id);

export const removeGood = (id) => apiProvider.remove(baseURL, id);
