import { Table } from "antd";
import ActionColumn from "../../components/table/ActionColumn";
import EditGoodService from "../edit/EditGoodService";
import TableHeader from "./TableHeader";

const columns = [
  { title: "NOMBRE", dataIndex: "name" },
  { title: "TIPO", dataIndex: "type" },
  {
    title: "ACCIÓN",
    key: "actions",
    render: (text, record) => (
      <ActionColumn
        record={record}
        deleteRecord={() => console.log("Borrando bien/servico")}
        modal={<EditGoodService record={record} />}
      />
    ),
  },
];

const data = [
  { name: "Nombre del bien 1", type: "Bien" },
  { name: "Nombre del bien 2", type: "Bien" },
  { name: "Nombre del servicio 3", type: "Servicio" },
  { name: "Nombre del bien 4", type: "Bien" },
  { name: "Nombre del servicio 5", type: "Servicio" },
];

const GoodServicesTable = () => {
  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Ubicaciones</h1>
      </div>
      <Table
        dataSource={data}
        title={() => <TableHeader data={data} />}
        columns={columns}
        loading={false}
        pagination={false}
        className="mt-12 px-16"
      />
    </>
  );
};

export default GoodServicesTable;
