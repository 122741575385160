import { UserOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import CreateUser from "../create/CreateUser";

const TableHeader = ({ setType, users }) => {
  return (
    <div className="bg-slate-100 px-7 py-6">
      <div className="w-5/12 mb-7 flex">
        <Input
          suffix={<UserOutlined />}
          className="mr-5"
          placeholder="Buscar usuario"
          type="search"
        />
        <div className="text-left">
          <Select
            placeholder="Tipo de usuario"
            onSelect={(option) => setType(option)}
          >
            <Select.Option value="cms">Usuarios CMS</Select.Option>
            <Select.Option value="app">Usuarios App</Select.Option>
          </Select>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between w-1/4">
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total usuarios App</span>
            <p className="text-lg font-semibold">13 usuarios</p>
          </div>
          <div className="h-12 text-left">
            <span className="text-zinc-500">Total usuarios CMS</span>
            <p className="text-lg font-semibold">{users.cms} usuarios</p>
          </div>
        </div>
        <div>
          <CreateUser />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
