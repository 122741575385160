import { Badge, Form, Input, Select } from "antd";
import DependencySelect from "../../components/form/DependencySelect";
import PhotoUpload from "../../components/form/PhotoUpload";
import { colors, modalInput } from "../../styles/formFields";

const formInput = { ...modalInput, fontSize: "12px", height: "32px" };

const PersonnelForm = ({ submit, pointer, values = {} }) => (
  <Form
    form={pointer}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
    onSubmit={submit}
  >
    <div className="flex">
      <div className="pr-2 w-1/2 flex flex-col">
        <PhotoUpload width={292} height={264} name="avatar" />

        <DependencySelect className="mt-7" initialValue={values?.entity?.id} />

        <Form.Item
          name="staff_type_id"
          style={{ marginBottom: "18px", marginTop: "-7px" }}
          label="Cargo"
          initialValue={values.rol?.id}
        >
          <Select
            placeholder="Selecciona un cargo"
            style={formInput}
            bordered={false}
          >
            <Select.Option value={1}>Coordinador</Select.Option>
            <Select.Option value={2}>Jefe de brigada</Select.Option>
            <Select.Option value={3}>Brigadista</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="pl-2 w-1/2 flex flex-col">
        <Badge
          color={colors.primary}
          text="Datos generales"
          className="font-bold"
        />

        <Form.Item
          style={{ marginBottom: "18px" }}
          label="Nombre Completo"
          name="name"
          initialValue={values.name}
          rules={[{ required: true, message: "Este campo es necesario" }]}
        >
          <Input placeholder="Ingresa nombre y apellido" style={formInput} />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "18px" }}
          label="Teléfono"
          name="cellphone"
          initialValue={values.cellphone}
          rules={[{ required: true, message: "Este campo es necesario" }]}
        >
          <Input placeholder="Ingresa número de teléfono" style={formInput} />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "18px" }}
          label="Correo electrónico"
          name="email"
          initialValue={values.email}
          rules={[
            { required: true, message: "Este campo es necesario" },
            { type: "email", message: "El email ingresado no es válido" },
          ]}
        >
          <Input
            placeholder="Ingresa el correo electrónico"
            style={formInput}
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "18px" }}
          label="Usuario"
          name="username"
          initialValue={values.username}
          rules={[{ required: true, message: "Este campo es necesario" }]}
        >
          <Input placeholder="Ingresa un nombre de usuario" style={formInput} />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "18px" }}
          label="Contraseña"
          name="password"
          rules={[
            { required: true, message: "Este campo es necesario" },
            { min: 8, message: "Mínimo 8 caracteres" },
          ]}
        >
          <Input.Password
            placeholder="Ingresa tu contraseña"
            style={formInput}
          />
        </Form.Item>
      </div>
    </div>
  </Form>
);

export default PersonnelForm;
