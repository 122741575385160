import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { addMember } from "./groupSlice";

const AddUser = ({ groupId, userType }) => {
  const dispatch = useDispatch();

  const handleAddUser = () => {
    dispatch(addMember({ groupId, userType }));
  };

  return (
    <button
      className="flex flex-col bg-transparent justify-center items-center px-2 py-3 text-primary"
      type="button"
      onClick={handleAddUser}
    >
      <span className="border border-dashed rounded-full border-primary py-1 px-2 mb-1">
        <PlusOutlined />
      </span>
      Nuevo
    </button>
  );
};

export default AddUser;
