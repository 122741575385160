/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Form, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { brandsIndex, modelsIndex } from "../../../services/vehicleService";
import DependencySelect from "../../components/form/DependencySelect";
import { colors, modalInput } from "../../styles/formFields";

const VehicleForm = ({ submit, pointer, values = {} }) => {
  const formInput = {
    ...modalInput,
    fontSize: "12px",
    height: "32px",
    width: "100%",
  };

  const { vehicle_brand: vehicleBrand, vehicle_model: vehicleModel } = values;

  const [brands, setBrands] = useApi(brandsIndex);
  const [brand, setBrand] = useState(vehicleBrand?.id ?? "");
  const [models, setModels] = useApi(() => modelsIndex(brand));

  useEffect(() => {
    setBrands();
  }, []);

  useEffect(() => {
    setModels();
  }, [brand]);

  return (
    <Form
      form={pointer}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onSubmit={submit}
    >
      <Badge
        color={colors.primary}
        text="Datos generales"
        className="font-bold"
      />
      <div className="flex">
        <div className="pr-2 w-1/2 flex flex-col mt-3">
          <Form.Item
            label="Marca"
            initialValue={vehicleBrand?.id}
            name="brand"
          >
            <Select
              placeholder="Seleccione la marca"
              style={formInput}
              bordered={false}
              onChange={(value) => setBrand(value)}
            >
              {brands.isSuccess &&
                brands.data.map((brand) => (
                  <Select.Option key={brand.name} value={brand.id}>
                    {brand.name}
                  </Select.Option>
                ))}
              P
            </Select>
          </Form.Item>

          <Form.Item name="color" label="Color" initialValue={values.color}>
            <Select
              placeholder="Selecciona un color"
              style={formInput}
              bordered={false}
            >
              {[
                "Azul",
                "Blanco",
                "Rojo",
                "Verde",
                "Amarillo",
                "Negro",
                "Gris",
              ].map((color, value) => (
                <Select.Option key={color} value={value}>
                  {color}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Placa"
            name="plate"
            initialValue={values["license_plate"]}
            rules={[{ required: true, message: "Este campo es necesario" }]}
          >
            <Input placeholder="Escriba la placa" style={formInput} />
          </Form.Item>
        </div>
        <div className="pl-2 w-1/2 flex flex-col mt-3">
          <Form.Item
            label="Modelo"
            name="model"
            initialValue={vehicleModel?.id}
            rules={[{ required: true, message: "Este campo es necesario" }]}
          >
            <Select
              placeholder="Seleccione la marca"
              style={formInput}
              bordered={false}
            >
              {models.isSuccess &&
                models.data.map((model) => (
                  <Select.Option key={model.name} value={model.id}>
                    {model.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Año"
            name="year"
            initialValue={values.year}
            rules={[{ required: true, message: "Este campo es necesario" }]}
          >
            <InputNumber placeholder="Escriba el año" style={formInput} />
          </Form.Item>

          <DependencySelect initialValue={values.entities ? values.entities[0].id : null} />
        </div>
      </div>
    </Form>
  );
};

export default VehicleForm;
