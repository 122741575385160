import { Form, Button, Space, Modal } from "antd";
import { EditFilled, CloseCircleOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { editDependency } from "../../../services/dependencyService";
import { selectSession } from "../../auth/sessionSlice";
import DependencyForm from "../form/DependencyForm";
import ModalTitle from "../../components/modal/ModalTitle";
import ModalButtons from "../../components/modal/ModalButtons";

const EditDependency = ({ record }) => {
  const [form] = Form.useForm();
  const { token } = useSelector(selectSession);
  const [visible, setVisible] = useState(false);

  const [request, setRequest] = useApi(() =>
    editDependency({ ...form.getFieldsValue(), id: record.id }, token)
  );

  useEffect(() => {
    if (request.isSuccess) window.location.reload();
  }, [request]);

  return (
    <>
      <Button type="text" size="small" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <EditFilled style={{ color: "rgb(0, 208, 208)" }} />
        </Space>
      </Button>
      <Modal
        title={<ModalTitle title="Editar" subtitle="Dependencia" />}
        closeIcon={<CloseCircleOutlined />}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        {request.error && (
          <Form.ErrorList
            errors={[request.error]}
            className="mb-4 text-red-500"
          />
        )}
          <DependencyForm values={record} submit={() => setRequest()} pointer={form} />

          <ModalButtons align="right" onCancel={() => setVisible(false)} onOk={setRequest} />
      </Modal>
    </>
  );
};

export default EditDependency;
