/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloseCircleOutlined,
  EditFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import {
  editSocialProgram,
  socialProgramShow,
} from "../../../services/socialProgramService";
import { selectSession } from "../../auth/sessionSlice";
import { addFlash } from "../../components/flash/flashSlice";
import ModalButtons from "../../components/modal/ModalButtons";
import ModalTitle from "../../components/modal/ModalTitle";
import FormTabs from "../form/FormTabs";
import styles from "./ProgramModal.module.css";

const EditProgram = ({ id, editable = true, onSuccess }) => {
  const { token } = useSelector(selectSession);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();

  const [program, setProgram] = useApi(() => socialProgramShow(id, token));
  const [request, setRequest] = useApi(() =>
    editSocialProgram({ ...form.getFieldsValue(), id: id }, token)
  );

  useEffect(() => {
    if (visible && !program.data) {
      setProgram();
    }
  }, [visible]);

  useEffect(() => {
    if (request.isSuccess && !request.error) {
      setVisible(false);
      onSuccess();
      dispatch(
        addFlash({
          message: "Se ha actualizado el programa satisfactoriamente ✅",
          status: "success",
        })
      );
    }

    if (request.error) {
      console.log(request.error);
      dispatch(
        addFlash({
          message: `¡Hubo problemas! el programa no se pudo actualizar 🚨`,
          status: "error",
        })
      );
      setVisible(false);
    }
  }, [request.isSuccess, request.error]);

  return (
    <>
      <Button type="text" size="small" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          {editable ? (
            <EditFilled style={{ color: "rgb(0, 208, 208)" }} />
          ) : (
            <EyeOutlined />
          )}
        </Space>
      </Button>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        closeIcon={<CloseCircleOutlined />}
        width="80%"
        title={<ModalTitle title="Programa" subtitle="Edición del Programa" />}
      >
        <div className="text-center">
          {[
            "Dependencia",
            "Duración",
            "Bienes y Servicios",
            "Grupo de Trabajo",
            "Nombre",
            "Lugar de Aplicación",
          ].map((item, index) => (
            <button
              key={`tabBtn-${index}`}
              onClick={() => setCurrentTab(index)}
              className={`${styles.tab} ${
                index === currentTab ? styles.active : ""
              }`}
            >
              {item}
            </button>
          ))}
        </div>
        {program.isSuccess && (
          <Form form={form}>
            <FormTabs activeTab={currentTab} values={program.data} />
          </Form>
        )}
        {editable && (
          <ModalButtons
            align="right"
            className="w-36 h-8 text-sm"
            onCancel={() => setVisible(false)}
            onOk={() => setRequest()}
          />
        )}
      </Modal>
    </>
  );
};

export default EditProgram;
