import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { importCsv } from "../../../services/api/provider";
import { addFlash } from "../flash/flashSlice";
import ModalButtons from "../modal/ModalButtons";
import ModalTitle from "../modal/ModalTitle";

const LoadCsv = ({ resource, name, listing }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const fileInput = useRef();
  const [response, setResponse] = useApi(() =>
    importCsv(resource, name, fileInput.current.files[0])
  );

  useEffect(() => {
    if (response.isSuccess && !response.error) {
      dispatch(
        addFlash({
          message: "Archivos cargados satisfactoriamente ✅",
          status: "success",
        })
      );
      setVisible(false);
    } else if (response.error) {
      dispatch(
        addFlash({
          message:
            "Hubo problemas con la carga, verificar el archivo y volver a cargar🚨",
          status: "error",
        })
      );
      setVisible(false);
    }
  }, [dispatch, response]);

  const handleFileUpload = () => {
    setResponse();
  };

  return (
    <>
      <Button size="large" className="mr-5" onClick={() => setVisible(true)}>
        <Space className="text-sm">
          <UploadOutlined /> CARGAR .CSV
        </Space>
      </Button>

      <Modal
        title={
          <ModalTitle
            title="Cargar Archivo"
            subtitle={`Cargar CSV a ${listing}`}
          />
        }
        visible={visible}
        closeIcon={<CloseCircleOutlined />}
        footer={null}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <p className="text-lg font-medium">
          ¡Estimado usuario! está a punto de cargar elementos al sistema, por
          favor seleccionar un archivo en formato .csv
        </p>

        <input
          type="file"
          className="hidden"
          accept=".csv"
          ref={fileInput}
          onChange={(e) => handleFileUpload(e)}
        />

        <ModalButtons
          onOk={() => fileInput.current.click()}
          onCancel={() => setVisible(false)}
          nextText="Seleccionar Archivo"
        />
      </Modal>
    </>
  );
};

export default LoadCsv;
