import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { goodsIndex } from "../../../services/goodService";
import { removeInventory } from "../../../services/inventoryService";
import { servicesIndex } from "../../../services/serviceService";
import ActionColumn from "../../components/table/ActionColumn";
import EditGoodService from "../edit/EditGoodService";
import HistoryDrawer from "./HistoryDrawer";
import TableHeader from "./TableHeader";

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Tipo",
    dataIndex: "type",
    filters: [
      {
        text: "Bien",
        value: "Bien",
      },
      {
        text: "Servicio",
        value: "Servicio",
      },
    ],
    onFilter: (value, record) => record.type === value,
  },
  {
    title: "Dependencia",
    dataIndex: "entityName",
    sorter: (a, b) => a.entityName.localeCompare(b.entityName),
  },
  {
    title: "Cantidad",
    dataIndex: "available",
    sorter: (a, b) => a.available - b.available,
  },
  {
    title: "Presentación",
    dataIndex: "presentation",
    sorter: (a, b) => a.name.localeCompare(b.name),
    filters: [
      {
        text: "Taller",
        value: "taller",
      },
      {
        text: "Unidad",
        value: "unidad",
      },
      {
        text: "Granel",
        value: "granel",
      },
    ],
  },
  {
    title: "Opciones",
    key: "actions",
    render: (text, record) => (
      <ActionColumn
        record={record}
        modal={<EditGoodService type={record.type} values={record} />}
        deleteRecord={removeInventory}
        deleteOptions={{
          title: "Alerta",
          subtitle: "Eliminar Bien/Servicio",
          message:
            "Estimado usuario, está a punto de eliminar un bien del inventario.",
        }}
      />
    ),
  },
];

const locale = {
  items_per_page: "/paginas",
  jump_to: "ir a",
  jump_to_confirm: "confirmar",
  next_3: "Próximas 3 págs",
  next_5: "Próximas 5 págs",
  next_page: "Siguiente",
  page: "Página",
  page_size: "Tamaño de páginas",
  prev_3: "Anteriores 3 págs",
  prev_5: "Anteriores 5 págs",
  prev_page: "Anterior",
};

const InventoryTable = () => {
  const [goods, setGoods] = useApi(goodsIndex);
  const [services, setServices] = useApi(servicesIndex);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setGoods();
    setServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataReset = () => {
    setTableData(
      [...goods.data, ...services.data].map((data) => ({
        ...data,
        key: data.name,
        type: data.hasOwnProperty("status") ? "Bien" : "Servicio",
        entityName: data.entity ? data.entity.name : data.social_program.name,
      }))
    );
  };

  useEffect(() => {
    if (goods.isSuccess && services.isSuccess) {
      handleDataReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goods, services]);

  const handleDrawerVisible = (e) => {
    if (e.target.classList.contains("ant-table-cell")) {
      setDrawerVisible(true);
    }
  };

  const isFetching = goods.isFetching || services.isFetching;
  const isError = goods.error || services.error;
  const isSuccess = goods.isSuccess && services.isSuccess;

  return (
    <>
      <div className="index-table-title">
        <h1 className="text-3xl">Inventarios</h1>
      </div>
      {isFetching && <Spin className="mt-10" />}
      {isError && (
        <p className="text-red-500 mt-8 text-lg">
          {goods.error || services.error}
        </p>
      )}
      {isSuccess && (
        <Table
          showSorterTooltip={false}
          dataSource={tableData}
          title={() => (
            <TableHeader
              data={tableData}
              onSearch={setTableData}
              onReset={handleDataReset}
            />
          )}
          columns={columns}
          loading={false}
          pagination={{
            locale: locale,
            position: "bottomRight",
            pageSizeOptions: [20, 30, 40, 50, 60, 90, 100],
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          className="mt-12 px-16"
          onRow={() => ({
            onClick: (e) => handleDrawerVisible(e),
          })}
          expandable={{
            expandedRowRender: (record) => (
              <p className="mb-0 px-12">{record.description}</p>
            ),
            rowExpandable: (record) => record.description !== "",
          }}
        />
      )}
      <HistoryDrawer visible={drawerVisible} setVisible={setDrawerVisible} />
    </>
  );
};

export default InventoryTable;
