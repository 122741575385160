import style from "./Tag.module.less";

const Tag = ({ text, border, color, background, className = "" }) => {
  const lightenDarkenColor = (color, amount) => {
    let colorWithoutHash = color.replace("#", "");
    if (colorWithoutHash.length === 3) {
      colorWithoutHash = colorWithoutHash
        .split("")
        .map((c) => `${c}${c}`)
        .join("");
    }

    const getColorChannel = (substring) => {
      let colorChannel = parseInt(substring, 16) + amount;
      colorChannel = Math.max(Math.min(255, colorChannel), 0).toString(16);

      if (colorChannel.length < 2) {
        colorChannel = `0${colorChannel}`;
      }

      return colorChannel;
    };

    const colorChannelRed = getColorChannel(colorWithoutHash.substring(0, 2));
    const colorChannelGreen = getColorChannel(colorWithoutHash.substring(2, 4));
    const colorChannelBlue = getColorChannel(colorWithoutHash.substring(4, 6));

    return `#${colorChannelRed}${colorChannelGreen}${colorChannelBlue}`;
  };

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return [
        parseInt(result[1], 16), // R
        parseInt(result[2], 16), // G
        parseInt(result[3], 16), // B
      ];
    }
  };

  const getTextColor = (hex) => {
    const rgb = hexToRgb(hex);
    if (rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 186) {
      return "#333";
    } else {
      return "white";
    }
  };

  const bgColor = background ?? lightenDarkenColor(border, 118);
  const textColor = color ?? getTextColor(bgColor);

  return (
    <div
      className={`${style.tag} ${className} px-[6px]`}
      style={{
        borderColor: border,
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {text}
    </div>
  );
};

export default Tag;
