import { apiProvider } from './api/provider';
const baseURL = '/api/v1/inventories';

export const createInventory = (model) => apiProvider.post(
  baseURL,
  {
    name: model.name,
    description: model.description,
    entity_id: model.entity_id,
    amount: model.amount,
    available: model.available,
  }
);

export const editInventory = (model) => apiProvider.patch(
  baseURL,
  {
    id: model.id,
    name: model.name,
    description: model.description,
    entity_id: model.entity_id,
    amount: model.amount,
    available: model.available,
  }
);

export const inventoriesIndex = () => apiProvider.getAll(baseURL);

export const inventoryShow = (id) => apiProvider.getSingle(baseURL, id);

export const removeInventory = (id) => apiProvider.remove(baseURL, id);
