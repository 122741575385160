import { Cascader } from "antd";

const subLevel = (level) =>
  [1, 2, 3, 4].map((i) => ({
    value: `sub${i}`,
    label: `Sub Level ${level} Dependencia 0${i}`,
    children: level === 1 ? subLevel(2) : [],
  }));

const topLevel = (dependency) => ({
  value: dependency,
  label: dependency,
  children: subLevel(1),
});

const options = [
  {
    value: "sin asignar",
    label: "Sin asignar",
  },
  topLevel("Transporte"),
  topLevel("Educación"),
  topLevel("Deporte"),
  topLevel("Comercio"),
  topLevel("Ciencia"),
  topLevel("Agricultura"),
];

const DependencyCascader = (props) => (
  <Cascader
    {...props}
    options={options}
    changeOnSelect
    placeholder="Dependencia"
    displayRender={(label) => label[label.length - 1]}
  />
);

export default DependencyCascader;
