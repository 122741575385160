import { Badge, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useState } from "react";
import DependencyCascader from "../../components/form/DependencyCascader";
import { colors, modalInput } from "../../styles/formFields";

const GoodFields = ({ values }) => {
  const validation = [{ required: true, message: "Este campo es necesario" }];
  const [isUnit, setIsUnit] = useState(null);

  const handleUnitSelect = (value) => {
    if (value === "unidad") {
      setIsUnit(true);
      return;
    }

    setIsUnit(false);
  };

  return (
    <>
      <Badge
        color={colors.primary}
        text="Datos generales"
        className="font-bold"
      />
      <Row className="mt-6">
        <Col span={11}>
          <Form.Item rules={validation} label="Seleccionar bien" name="name" initialValue={values.name}>
            <Input style={modalInput} placeholder="Nombre" />
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <Form.Item rules={validation} label="Dependencia" name="dependency" initialValue={values.dependency}>
            <DependencyCascader bordered={false} style={modalInput} />
          </Form.Item>
        </Col>
      </Row>
      <Badge
        color={colors.primary}
        text="Resguardo del bien"
        className="font-bold"
      />
      <Row className="mt-6">
        <Col span={11}>
          <Form.Item
            rules={validation}
            label="Responsable del bien"
            name="user"
            initialValue={values.user}
          >
            <Input style={modalInput} placeholder="Escribir nombre" />
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <Form.Item
            rules={validation}
            label="Ubicación donde será resguardado el bien"
            name="location"
            initialValue={values.location}
          >
            <Input style={modalInput} placeholder="Escribir dirección" />
          </Form.Item>
        </Col>
      </Row>
      <Badge
        color={colors.primary}
        text="Detalles del bien"
        className="font-bold"
      />
      <Row className="mt-6">
        <Col span={11}>
          <Form.Item rules={validation} label="Individual o Granel" name="type" initialValue={values.type}>
            <Select
              placeholder="Seleccione opción"
              style={modalInput}
              bordered={false}
              onChange={(value) => handleUnitSelect(value)}
            >
              <Select.Option value="unidad">Individual</Select.Option>
              <Select.Option value="granel">Granel</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item rules={validation} label="Unidad de medida" name="measure" initialValue={values.measure}>
            <Select
              placeholder="Seleccione unidad de medida"
              style={modalInput}
              bordered={false}
            >
              {isUnit && <Select.Option value="unidad">Unidad</Select.Option>}

              {!isUnit &&
                [
                  "Kilogramo",
                  "Gramos",
                  "Miligramos",
                  "Libras",
                  "Litros",
                  "Centímetros Cúbicos",
                  "Milímetros Cúbicos",
                ].map((i) => (
                  <Select.Option key={i} value={i}>
                    {i}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <Form.Item rules={validation} label="Cantidad" name="quantity" initialValue={values.amount}>
            <InputNumber
              min={0}
              style={{ ...modalInput, width: "100%" }}
              placeholder="Escriba la cantidad"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default GoodFields;
