import { PoweroffOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { signout } from '../../services/authService';
import { selectSession } from '../auth/sessionSlice';
import { logout } from "./sessionSlice";

const LogoutButton = () => {
  const { token } = useSelector(selectSession)
  const dispatch = useDispatch();
  
  const handleLogout = async () => {
    const response = await signout(token);
    if (!response.loggedOut) {
      // Include erros on form
      console.log(response.errors);
      return
    }

    window.localStorage.removeItem('session');
    dispatch(logout());
  };
  
  return (
    <Button
      icon={<PoweroffOutlined />}
      type="primary"
      className="border-slate-500 hover:bg-red-500 hover:border-zinc-400"
      onClick={handleLogout}
    >
      Cerrar sesión
    </Button>
  )
};

export default LogoutButton;
