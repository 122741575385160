import { Col, Form, Input, InputNumber, Row } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../hooks/useApi";
import { createService } from "../../../services/serviceService";
import { selectSession } from "../../auth/sessionSlice";
import { addFlash } from "../../components/flash/flashSlice";
import DependencyCascader from "../../components/form/DependencyCascader";
import ModalButtons from "../../components/modal/ModalButtons";
import { modalInput } from "../../styles/formFields";

const ServiceForm = ({ closeModal, values = {} }) => {
  const [form] = Form.useForm();
  const { token } = useSelector(selectSession);
  const dispatch = useDispatch();
  const [response, setResponse] = useApi(() =>
    createService(
      {
        ...form.getFieldsValue(),
        social_program_id: 2,
        type_ids: [1],
      },
      token
    )
  );

  useEffect(() => {
    if (response.isSuccess && !response.error) {
      dispatch(
        addFlash({
          message: "Servicio nuevo creado satisfactoriamente ✅",
          status: "success",
        })
      );
      closeModal();
    } else if (response.error) {
      dispatch(
        addFlash({
          message: "Hubo un problema al crear el servicio, intente de nuevo 🚨",
          status: "error",
        })
      );
      closeModal();
    }
  }, [closeModal, dispatch, response]);

  const validation = [{ required: true, message: "Este campo es necesario" }];

  return (
    <>
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={11}>
            <Form.Item
              rules={validation}
              label="Elegir servico"
              name="name"
              initialValue={values.name}
            >
              <Input style={modalInput} placeholder="Nombre" />
            </Form.Item>

            <Form.Item
              rules={validation}
              label="Dependencia"
              name="dependency"
              initialValue={values.dependency}
            >
              <DependencyCascader bordered={false} style={modalInput} />
            </Form.Item>
          </Col>

          <Col span={11} offset={2}>
            <Form.Item
              rules={validation}
              label="Cantidad del servico"
              name="available"
              initialValue={values.amount}
            >
              <InputNumber
                style={{ ...modalInput, width: "100%" }}
                min={0}
                placeholder="Escribir la cantidad"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="description"
          label={
            <label>
              Descripción{" "}
              <span className="text-[10px] text-lightgrey-200">opcional</span>
            </label>
          }
          initialValue={values.description}
        >
          <Input.TextArea
            placeholder="Descripción"
            style={{ ...modalInput, height: "155px" }}
          />
        </Form.Item>

        <Form.Item>
          <ModalButtons
            onOk={() => {
              form
                .validateFields()
                .then(() => {
                  setResponse();
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
            onCancel={closeModal}
            nextText="Crear"
            align="end"
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default ServiceForm;
