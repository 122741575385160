import { createSlice } from '@reduxjs/toolkit';

const initialState = () => {
  if (window.localStorage.getItem('session')) {
    return JSON.parse(window.localStorage.getItem('session'));
  }

  return ({
    active: false,
    email: '',
    token: '',
  })
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,

  reducers: {
    login: (state, action) => {
      const { email, token, active } = action.payload;

      state.active = active;
      state.email = email;
      state.token = token;
    },
    logout: (state) => {
      state.active = false;
      state.email = '';
      state.token = '';
    }
  }
});

export const { login, logout } = sessionSlice.actions;

export const selectSession = state => state.session;

export default sessionSlice.reducer;
