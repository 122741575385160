import { Form, Button, Input } from 'antd';
import { useSelector } from 'react-redux';
import { selectSession } from '../auth/sessionSlice';

const EditUser = () => {
  const { email } = useSelector(selectSession);

  const handleSubmit = () => {
    console.log('Conectando con el servidor...');
  };
  
  return (
    <div className="w-1/2 mx-auto py-10">
      <h1 className="text-3xl text-left mb-5">Actualizar información de la cuenta</h1>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Actualizar correo"
          name="email"
          rules={[
            { required: true, message: 'Este campo es necesario' },
            { type: 'email', message: 'El email ingresado no es válido' }
          ]}
        >
          <Input placeholder="Ingresa tu email" defaultValue={email} />
        </Form.Item>

        <Form.Item
          label="Actualizar contraseña"
          name="password"
          rules={[
            { required: true, message: 'Este campo es necesario' },
            { min: 8, message: 'Mínimo 8 caracteres' }
          ]}
        >
          <Input.Password placeholder="Ingresa tu contraseña" />
        </Form.Item>

        <Form.Item wrapperCol={{ sm: { span: 24, offset: 0 } }}>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
};

export default EditUser;
