import { useEffect, useState } from "react";
import { Form, Button } from "antd";
import { createSocialProgram } from "../../../services/socialProgramService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { programsIndex } from "../../../routes";
import { useApi } from "../../../hooks/useApi";
import Steps from "../../components/creation/Steps";
import FormTabs from "../form/FormTabs";
import { resetGroups, selectGroups } from "../form/GroupCreation/groupSlice";
import { useDispatch } from "react-redux";

const stepValidation = {
  0: (values) => values.entity > 0,
  1: (values) => values.dates !== null,
  2: (values) => values["goods_and_services"].length > 0,
  3: () => true,
  4: () => true,
  5: (values) => values.name.trim().length > 0,
  6: (values) => values.municipality > 0,
};

const CreateProgram = () => {
  const [form] = Form.useForm();
  const groups = useSelector(selectGroups);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [latestStep, setLatestStep] = useState(0);
  const [allowNext, setAllowNext] = useState(false);

  useEffect(() => {
    dispatch(resetGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
    setAllowNext(latestStep > step);
  };

  const handleStepValidation = () => {
    const valid = stepValidation[currentStep](form.getFieldsValue());

    if (!valid) {
      setAllowNext(false);
      return;
    }

    setAllowNext(true);

    if (currentStep === latestStep) setLatestStep(currentStep + 1);

    if (currentStep === 2) {
      setLatestStep(5);
    }
  };

  const handleNextClick = () => {
    setAllowNext(latestStep > currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const [response, setResponse] = useApi(() =>
    createSocialProgram(form.getFieldsValue())
  );

  useEffect(() => {
    if (response.isSuccess) navigate(programsIndex);
  }, [navigate, response]);

  useEffect(() => {
    form.setFieldsValue({ groups: JSON.stringify(groups) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  return (
    <div className="p-16 py-0">
      <h1 className="index-table-title p-0 text-3xl">Programas</h1>
      <div className="p-7 mt-7 bg-white">
        <Steps
          currentStep={currentStep}
          allowedSteps={allowNext ? latestStep : currentStep}
          stepClick={handleStepClick}
        />

        {response.error && (
          <Form.ErrorList
            errors={[Object.values(response.error)]}
            className="mb-4 text-red-500"
          />
        )}

        <Form
          onFinish={() => setResponse()}
          form={form}
          className="text-left"
          onValuesChange={handleStepValidation}
        >
          <FormTabs activeTab={currentStep} />

          <Form.Item name="groups" />

          <div className="w-1/6 ml-auto mr-0">
            {currentStep < 6 && (
              <Button
                type="primary"
                onClick={handleNextClick}
                disabled={!allowNext}
                block
              >
                Siguiente
              </Button>
            )}

            {currentStep === 6 && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!allowNext}
                >
                  Finalizar
                </Button>
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateProgram;
